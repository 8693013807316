import React from "react";
import './datastyle.css'

const DataTable = () => {
  const data1 = [
    {
      group: "Müşteri-Kullanıcı",
      description:
        "Şirket'in sahip olduğu ALOHA uygulamasını kullanan konuşmacı ve dinleyicileri ifade eder.",
    },
    {
      group: "Ortak",
      description: "Şirket'te pay sahibi olan kişileri ifade eder.",
    },
    {
      group: "Tedarikçi Çalışanı",
      description: "Şirket'e ürün veya hizmet sunan kişilerin çalışanları",
    },
    {
      group: "Tedarikçi Yetkilisi/Gerçek Kişi Tedarikçi",
      description:
        "Şirket'e ürün veya hizmet sunan şirketin yetkilisi ya da ürün veya hizmet sunan gerçek kişi",
    },
    { group: "Ziyaretçi", description: "Şirket'i ziyaret eden kişiler" },
  ];

  const data2 = [
    { category: "Tedarikçiler", purpose: "Mal/hizmet tedarik eden kişiler" },
    {
      category: "Yetkili Kamu Kurum ve Kuruluşları",
      purpose:
        "İlgili kamu kurum ve kuruluşlarının hukuki yetkisi dahilinde talep ettiği amaçla sınırlı olarak",
    },
    {
      category: "Diğer-Hizmet Sağlayıcılar ve Danışmanlar",
      purpose:
        "Özellikle veri sorumlusu faaliyetlerinin güvenliği için çalışan hizmet sağlayıcılar, bulut hizmet sağlayıcıları aktarım gündeme gelmektedir.",
    },
  ];

  return (
    <div> <br />
      <h2>Kişisel Veri Sahipleri</h2>
      <table>
        <thead>
          <tr>
            <th>VERİ SAHİBİ KİŞİ GRUPLARI</th>
            <th>AÇIKLAMA</th>
          </tr>
        </thead>
        <tbody>
          {data1.map((item, index) => (
            <tr key={index}>
              <td>{item.group}</td>
              <td>{item.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
          <br />
      <h2>Paylaşılan Taraf Kategorileri</h2>
      <table>
        <thead>
          <tr>
            <th>PAYLAŞILAN TARAF KATEGORİSİ</th>
            <th>PAYLAŞMA AMACI</th>
          </tr>
        </thead>
        <tbody>
          {data2.map((item, index) => (
            <tr key={index}>
              <td>{item.category}</td>
              <td>{item.purpose}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};



export default DataTable;
