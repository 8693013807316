import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Privacy from "../src/pages/kvkk/Privacy";
import UserAgreement from "./pages/userAgreement/UserAgreement";
import InformationCookies from "./pages/informationCookies/InformationCookies";
import ApplicationForm from "./pages/applicationForm/ApplicationForm";
import HomePage from "./pages/HomePage";
// import Home from "./pages/home";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          {/* <Route path="/" element={<Home />} /> */}
          <Route path="/" element={<HomePage />} />
          <Route path="/kvkk" element={<Privacy />} />
          <Route path="/terms-of-Use" element={<UserAgreement />} />
          {/* <Route path="/privacy-policy" element={<UserInformation />} /> */}
          <Route path="/cookies-information" element={<InformationCookies />} />
          <Route path="/application-form" element={<ApplicationForm />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
