import React, { useEffect } from 'react';

const HomePage = () => {
  
  const handleClick = () => {
    window.location.href = 'https://alohaliveapp.com/';
  };

  useEffect(() => {
    handleClick();
  }, []);

  return (
    <div>
      <button style={{display:"none"}} onClick={handleClick}>Link</button>
    </div>
  );
};

export default HomePage;
