import React from "react";

const InformationCookies = () => {
  return (
    <div className="privacy-container">
      <p style={{ textDecoration: "underline" }}>
        <strong>
          <em>Çerezler Vasıtasıyla İşlenen Kişisel Verilere İlişkin</em>
        </strong>
      </p>
      <h3>AYDINLATMA METNİ</h3>
      <p>
        Barbaros Mahallesi Begonya Sokak Nidakule Ataşehir Batı Blok No:1 İç
        Kapı No:2 Ataşehir İstanbul merkezli Aloha Dijital Bilişim Eğitim ve
        Danışmanlık Anonim Şirketi (“Aloha” veya “Şirket”) olarak 6698 sayılı
        Kişisel Verilerin Korunması Kanunu’nda tanımlı veri sorumlusu sıfatıyla
        işlediğimiz kişisel verilerinizin güvenliğine önem veriyoruz. <br />{" "}
        <br />
        Bu nedenle sizleri web sitesi ve Uygulama ziyaretiniz (Bundan sonra web
        sitesi olarak anıldığında, her ikisi de kastedilecektir) sırasında
        çerezler vasıtasıyla işlenen kişisel verileriniz hakkında bilgilendirmek
        isteriz.
      </p>
      <h3>ÇEREZ nedir?</h3>
      <p>
        Çerezler, bir web sitesini, mobil platformu ziyaret ettiğinizde
        cihazınızda saklanan, bilgi içeren küçük metin dosyalarıdır. Çerez,
        deneyiminizi iyileştirmekte kullanılan bilgiler saklar ve web sitesinin
        sahibine kullanıcının davranışlarıyla ilgili belli bilgiler sağlar.{" "}
        <br /> <br />
        Çerez türleri ve kullanım amaçları aşağıda açıklanmaktadır. Bu
        çerezlerden bazıları veya tamamı halihazırda ALOHA web sitesinde
        kullanılıyor olabilir veya ileride kullanıma sokulabilir. Kullanılan
        ilgili çerezin türüne göre veri işleme amaçları ve ilgili çerezi
        kullanırken dayandığımız Kanun kapsamındaki veri işleme şartı
        değişebilmektedir.
      </p>
      <div className="row">
        <div
          style={{ fontWeight: "bold" }}
          className="col-md-6 d-flex align-items-center"
        >
          Zorunlu Çerezler
        </div>
        <div className="col-md-6 text-start">
          Web sitemizin doğru biçimde çalışması için zorunludur. Örneğin, kimlik
          doğrulama, mevcut oturumunuz ile ilgili bilgilerin kaybolmaması gibi
          amaçlarla zorunlu çerezler kullanılmaktadır. Bu çerezler güvenlik ve
          doğrulama gibi amaçlar için kullanılmakta olup, herhangi bir pazarlama
          amacı doğrultusunda kullanılmaz. <br /> <br />
          <strong>
            Bu çerezlerin kullanımı esnasında gerçekleştirdiğimiz veri işleme
            faaliyetleri için Kanun m.5 kapsamında “Bir sözleşmenin kurulması
            veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin
            taraflarına ait kişisel verilerin işlenmesinin gerekli olması” veri
            işleme şartına dayanmaktayız.
          </strong>
          <br />
          <br />
        </div>
      </div>
      <div className="row">
        <div
          style={{ fontWeight: "bold" }}
          className="col-md-6 d-flex align-items-center"
        >
          İşlevsellik İçin Gerekli Olan Çerezler
        </div>
        <div className="col-md-6 text-start">
          Web sitemizi ziyaret eden kullanıcıların tercihlerini hatırlamasına
          olanak sağlayan çerezlerdir. Örneğin ziyaretçinin dil tercihi veya
          metin font boyu seçiminin hatırlanmasını sağlar. Bu tür çerezlerin
          kullanımına izin verilmemesi size özel bir özelliğin kullanılmamasına
          neden olabilir ve tercihlerinizi hatırlamasını engeller. <br /> <br />
        </div>
      </div>
      <div className="row">
        <div
          style={{ fontWeight: "bold" }}
          className="col-md-6 d-flex align-items-center"
        >
          Performans ve Analiz İçin Gerekli Olan Çerezler
        </div>
        <div className="col-md-6 text-start">
          <strong>
            Bu çerezlerin kullanımı esnasında gerçekleştirdiğimiz veri işleme
            faaliyetleri için Kanun m.5 kapsamında “İlgili kişinin temel hak ve
            özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru
            menfaatleri için veri işlenmesinin zorunlu olması.” veri işleme
            şartına dayanmaktayız. Performans ve Analiz İçin Gerekli Olan
            Çerezler
          </strong>{" "}
          <br /> <br />
          Web sitemizin geliştirilmesine yardımcı olan çerezlerdir. Bu tür
          çerezler, ziyaretçilerin site kullanımları hakkında bilgiler toplar,
          sitenin gerektiği gibi çalışıp çalışmadığının denetiminde ve alınan
          hataların tespitinde kullanılır. <br /> <br />
          <strong>
            Bu çerezlerin kullanımı esnasında gerçekleştirdiğimiz veri işleme
            faaliyetleri için Kanun m.5 kapsamında “İlgili kişinin temel hak ve
            özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru
            menfaatleri için veri işlenmesinin zorunlu olması.” veri işleme
            şartına dayanmaktayız. <br />
            <br />
          </strong>
        </div>
      </div>
      <div className="row">
        <div
          style={{ fontWeight: "bold" }}
          className="col-md-6 d-flex align-items-center"
        >
          Hedefleme ve Reklam Çerezleri
        </div>
        <div className="col-md-6 text-start">
          Bu çerezler web sitemizde veya sitemiz haricindeki mecralarda ürün ve
          hizmet tanıtımını yapmak, iş birliği yaptığımız ortaklarımızla
          birlikte size ilgili ve kişiselleştirilmiş reklamlar göstermek, reklam
          kampanyalarının etkinliğini ölçmek için kullanılır. <br />
          <br />
          <strong>
            Bu çerezlerin kullanımı esnasında gerçekleştirdiğimiz veri işleme
            faaliyetleri için Kanun m.5 kapsamında “İlgili kişinin temel hak ve
            özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru
            menfaatleri için veri işlenmesinin zorunlu olması.” veri işleme
            şartına dayanmaktayız.
          </strong>
        </div>
      </div>
      <hr />
      <h3>ÇEREZLER nasıl toplanır?</h3>
      <p>
        Veriler tarayıcılara eriştiğiniz cihazlarınız aracılığıyla toplanır.
        Toplanan bu bilgiler cihazlara özeldir. İstenildiği zaman kullanıcı
        tarafından silinebilmekte ve bilgilere erişim kapatılabilmektedir.
      </p>
      <p>
        <strong>Aloha çerezleri ne için kullanıyor?</strong>
      </p>
      <p>
        Aloha çerezleri kullanıcı tercihlerinizi hatırlamak, anonim kullanıcı
        istatistikleri saklamak, pazarlama, hizmetlerimizin kalitesini artırmak
        ve insanların sitelerimizi nasıl kullandıklarını anlamak amacıyla
        kullanır. Çerezleri insanların sitelerimizi kullanma biçimine dair trend
        ve modelleri izlemekte kullanıyoruz.
      </p>
      <p>
        <strong>Bu web sitesi tarafından kullanılan çerezler?</strong>
      </p>
      <p>
        Hem “birinci taraf” çerezler (sadece Aloha tarafından kullanılan
        çerezler) hem de “üçüncü taraf” çerezler (bir üçüncü taraf websitesinden
        kaynaklanan çerezler) kullanıyoruz. Birinci taraf çerezler, sitemizin
        tamamının kullanılabilmesi için gerekli tercih ve verilerin
        saklanmasında kullanılmaktadır. Üçüncü taraf çerezleri, Google analytics
        gibi üçüncü taraf web istatistikleri sağlayıcılarının yardımıyla,
        kullanıcı tarafından oluşturulan trend ve modelleri takip etmekte
        kullanıyoruz. Bu çerezler sadece Aloha websiteleri ve web istatistik
        sağlayıcısı tarafından kullanılmaktadır. Bu bilgileri başka herhangi bir
        üçüncü tarafla paylaşmıyoruz. <br />
        <br />
        Aşağıdaki liste kullanılan çerezleri ve her bir çerezin amacını
        açıklamaktadır.
      </p>
      <ul>
        <li>
          ga* – Kullanıcı davranışlarını ve websitesi performansını izlemek için
          Google Analytics tarafından kullanılmaktadır.
        </li>
        <li>
          Access Token <br />
          Kullanıcının oturum kimliğini tutar ve sunucu tarafında iletişim kuran
          kullanıcıyı tanımak için kullanılır.
        </li>
        <li>
          Universal Device ID(UDID) <br />
          Kullanıcının bağlandığı cihazın kimliğini içerir ve sunucu tarafında
          kullanıcı eşleştirmesi için kullanılır.
        </li>
        <li>
          Kullanıcının IP Adresi <br />
          Güvenlik nedeniyle bu bilgiyi alıyoruz ve kullanıcı ile
          eşleştiriyoruz. <br />
          *Tüm çerezler iki yıllık bir süre için saklanmaktadır.
        </li>
      </ul>
      <p>
        <strong>Çerezleri nasıl kontrol edebilirsiniz?</strong>
      </p>
      <p>
        Bilgisayarınızda halihazırda bulunan çerezleri silebilir ve internet
        gezgininize çerez kaydedilmesini/yerleştirilmesini engelleyebilirsiniz.{" "}
      </p>
      <p>Örnek olarak, </p>
      <ul className="list-group">
        <li className="list-group-item">
          "Google Chrome &gt; Ayarlar &gt; Gelişmiş &gt; Gizlilik ve Güvenlik
          &gt; Site Ayarları &gt; Çerezler ve Site Verileri &gt; Sitelerin çerez
          verilerini kaydetmelerine ve okumalarına izin ver" seçeneği ile
          yönetebilirsiniz.
        </li>
        <li className="list-group-item">
          {" "}
          "Internet Explorer &gt; Ayarlar &gt; İnternet Seçenekleri &gt;
          Gizlilik &gt; Gelişmiş ayarlar" menüsünden yönetebilirsiniz.
        </li>
        <li className="list-group-item">
          Çoğu Internet gezgini aşağıdakileri yapmanıza olanak tanır
        </li>
        <li className="list-group-item">
          Kaydedilmiş çerezleri görüntüleme ve dilediklerinizi silme
        </li>
        <li className="list-group-item">Üçüncü taraf çerezleri engelleme</li>
        <li className="list-group-item">
          Belli sitelerden çerezleri engelleme
        </li>
        <li className="list-group-item">Tüm çerezleri engelleme</li>
        <li className="list-group-item">
          Internet gezginini kapattığınızda tüm çerezleri silme
        </li>
        <li className="list-group-item">
          Çerezleri silmeyi tercih ederseniz ilgili web sitesindeki
          tercihleriniz silinecektir. Ayrıca, çerezleri tamamen engellemeyi
          tercih ederseniz ALOHA’a ait web siteleri dahil olmak üzere birçok web
          sitesi ve dijital platform düzgün çalışmayabilir.
        </li>
        <p>Mobil Cihazınızda Çerezleri Kontrol etmek için ;</p>
        <p>Apple Cihazlarda;</p>
        <li className="list-group-item">
          "Ayarlar &gt; Safari &gt; Geçmişi ve Web Sitesi Verilerini Sil"
          adımları ile tarama geçmişinizi ve çerezleri temizleyebilirsiniz..
        </li>
        <li className="list-group-item">
          Çerezleri silip geçmişinizi tutmak için "Ayarlar &gt; Safari &gt;
          İleri Düzey &gt; Web Sitesi Verileri &gt; Tüm Web Sitesi Verilerini
          Sil" adımlarını izleyebilirsiniz.
        </li>
        <p>
          Siteleri ziyaret ederken geçmiş verilerinin tutulmasını
          istemiyorsanız;
        </p>
        <li className="list-group-item">
          "Safari &gt; ALOHA &gt; Özel &gt; Bitti" adımlarını izleyerek özel
          dolaşımı aktif hale getirebilirsiniz.
        </li>
        <li className="list-group-item">
          "Ayarlar &gt; Safari &gt; Tüm Çerezleri Engelle" adımları ile
          çerezleri engelleyebilirsiniz. Ancak; çerezleri engellediğinizde bazı
          web siteleri ve özellikler düzgün çalışmayabilir.
        </li>
        <p>Android Cihazlarda;</p>
        <li className="list-group-item">
          "Chrome uygulaması &gt; Ayarlar &gt; Gizlilik &gt; Tarama verilerini
          temizle &gt; Çerezler, medya lisansları ve site verileri &gt; Verileri
          Temizle" seçeneği ile çerezlerinizi temizleyebilirsiniz.
        </li>
        <li className="list-group-item">
          "Chrome Uygulaması &gt; Ayarlar &gt; Site Ayarları &gt; Çerezler"
          seçeneği ile çerezlere izin verebilir veya engelleyebilirsiniz.
        </li>
      </ul>
      <h2>KİŞİSEL VERİLERİNİZE İLİŞKİN HAKLARINIZ</h2>
      <p>
        Kanun’un 11. maddesi, ilgili kişilerin (kişisel verileri işlenen gerçek
        kişilerin) haklarını düzenlemektedir. Bu maddede belirtilen haklarınızı
        kullanmak ve taleplerinizi ALOHA’ya iletmek için aşağıdaki yöntemleri
        tercih edebilirsiniz:
      </p>
      <ul>
        <li style={{ listStyle: "none" }}>
          Taleplerinizi yazılı olarak Barbaros Mahallesi Begonya Sokak Nidakule
          Ataşehir Batı Blok No:1 İç Kapı No:2 Ataşehir İstanbul adresine
          gönderebilirsiniz. <br />
          Taleplerinizi güvenli elektronik imza, mobil imza ya da tarafımıza
          daha önce bildirdiğiniz ve sistemimizde kayıtlı bulunan elektronik
          posta adresinizi kullanmak suretiyle{" "}
          <a
            className="text-dark fw-bold"
            target="_blank"
            rel="noreferrer"
            href="mailto:kvkk@alohalive.online"
          >
            kvkk@alohalive.online
          </a>{" "}
          mail adresine iletebilirsiniz. <br />
          <a
            className="text-dark fw-bold"
            href="https://www.resmigazete.gov.tr/eskiler/2018/03/20180310-6.htm"
          >
            Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ
            (“Tebliğ”)’
          </a>
          de belirtilen diğer yöntemleri tercih edebilirsiniz.
        </li>
      </ul>
      <p>
        Başvurunuzda; ad, soyad ve başvuru yazılı ise imzanızın, Türkiye
        Cumhuriyeti vatandaşları için T.C. kimlik numaranızın, yabancılar için
        uyruğunuzun, pasaport numaranızın veya varsa kimlik numaranızın,
        tebligata esas yerleşim yeri veya iş yeri adresinizin, varsa bildirime
        esas elektronik posta adresi, telefon ve faks numaranızın ve talep
        konunuzun bulunması Tebliğ’in 5/2. maddesi uyarınca zorunlu olup; varsa
        talebinize ilişkin bilgi ve belgelerin başvuruya eklenmesi
        taleplerinizin en sağlıklı şekilde ve kısa bir süre içerisinde
        sonuçlandırılması bakımından önem arz etmektedir. Cevap vermeden önce
        kimliğinizi doğrulama hakkımız saklıdır.
      </p>
    </div>
  );
};

export default InformationCookies;
