import React from "react";
import "./Privacy.css";
import DataTable from "../../components/DataTable";
import DataRetentionTable from "../../components/DataRetentionTable";

const Privacy = () => {
  return (
    <div className="privacy-container">
      <h1>Aloha Live Kişisel Verilerin Korunması Politikası (KVKK)</h1> <br />
      <h2>Politika’nın Amacı</h2>
      <p>
        6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”) uyarınca, veri
        sorumlusu olan Barbaros Mahallesi Begonya Sokak Nidakule Ataşehir Batı
        Blok No:1 İç Kapı No:2 Ataşehir İstanbul adresinde bulunan,{" "}
        <strong>
          Aloha Dijital Bilişim Eğitim ve Danışmanlık Anonim Şirketi
        </strong>{" "}
        (“Aloha” veya “Şirket”) tarafından kişisel verilerin korunmasına büyük
        önem verilmekte, kişisel veri içeren tüm iş süreçlerinde Kanun’a uyum
        hedeflenmektedir. Bu itibarla, Şirket’in iş ilişkisi içinde olduğu tüm
        aktörlerin kişisel veriler ile temas ettiği gözetilerek, tüm tarafların
        uyması gereken kural ve prosedürleri içeren işbu Politika düzenlenmiş ve
        Şirket internet sitesinde yayına alınmıştır.
      </p>
      <h2>Tanımlar</h2>
      <ul>
        <li>
          <strong>Açık Rıza:</strong> Belirli bir konuya ilişkin,
          bilgilendirilmeye dayanan ve özgür iradeyle açıklanan rızayı,
        </li>
        <li>
          <strong>Anonim Hâle Getirme:</strong> Kişisel verilerin, başka
          verilerle eşleştirilerek dahi hiçbir surette kimliği belirli veya
          belirlenebilir bir gerçek kişiyle ilişkilendirilemeyecek hâle
          getirilmesini,
        </li>
        <li>
          <strong>İlgili Kişi:</strong> Kişisel verisi işlenen gerçek kişiyi,
        </li>
        <li>
          <strong>Kişisel Veri:</strong> Kimliği belirli veya belirlenebilir
          gerçek kişiye ilişkin her türlü bilgiyi,
        </li>
        <li>
          <strong>Özel Nitelikli (Hassas) Kişisel Veri:</strong> Kişilerin ırkı,
          etnik kökeni, siyasi düşüncesi, felsefi inancı, dini, mezhebi veya
          diğer inançları, kılık ve kıyafeti, dernek, vakıf ya da sendika
          üyeliği, sağlığı, cinsel hayatı, ceza mahkûmiyeti ve güvenlik
          tedbirleriyle ilgili verileri ile biyometrik ve genetik verilerini,
        </li>
        <li>
          <strong>Kişisel Verilerin İşlenmesi:</strong> Kişisel veriler üzerinde
          gerçekleştirilen her türlü işlemi,
        </li>
        <li>
          <strong>KVKK:</strong> 6698 sayılı Kişisel Verilerin Korunması
          Kanununu,
        </li>
        <li>
          <strong>KVKK Kurulu:</strong> Kişisel Verileri Koruma Kurulunu,
        </li>
        <li>
          <strong>KVKK Kurumu:</strong> Kişisel Verileri Koruma Kurumunu,
        </li>
        <li>
          <strong>Veri İşleyen:</strong> Veri sorumlusunun verdiği yetkiye
          dayanarak onun adına kişisel verileri işleyen gerçek veya tüzel
          kişiyi,
        </li>
        <li>
          <strong>Veri Kayıt Sistemi:</strong> Kişisel verilerin belirli
          kriterlere göre yapılandırılarak işlendiği kayıt sistemini,
        </li>
        <li>
          <strong>İrtibat Kişisi:</strong> Türkiye’de yerleşik olan tüzel
          kişiler ile Türkiye’de yerleşik olmayan tüzel kişi veri sorumlusu
          temsilcisinin Kanun ve bu Kanuna dayalı olarak çıkarılacak ikincil
          düzenlemeler kapsamındaki yükümlülükleriyle ilgili olarak, Kurum ile
          kurulacak iletişim için veri sorumlusu tarafından Sicile kayıt
          esnasında bildirilen gerçek kişiyi
        </li>
        <li>
          <strong>Veri Sorumlusu:</strong> Kişisel verilerin işleme amaçlarını
          ve vasıtalarını belirleyen, veri kayıt sisteminin kurulmasından ve
          yönetilmesinden sorumlu olan gerçek veya tüzel kişiyi ifade eder.
        </li>
      </ul>
      <h2>Politika’nın Kapsamı</h2>
      <p>
        Politika hükümleri, Şirket çalışanlarını, danışmanlarını, ziyaretçileri,
        tedarikçileri, kullanıcıları (müşterileri) ve tüm iş ortaklarını
        ilgilendirir. Şirket ile kurulan her türlü ilişki tarafının bu
        Politika’yı ayrıntılı incelemesi ve Politika hükümlerine uygun
        davranması gerekmektedir.
      </p>
      <h3>3.1 Şirket’in Hukuki İlişkide Olduğu Taraflar</h3>
      <p>
        Şirket’in hukuki ilişkide olduğu taraflar, daima veri koruma ilkelerine
        uygun hareket edecektir. Veri işleme sürecinde uygun hukuki sebebin var
        olduğu tespit edilse dahi, veri koruma ilkelerine aykırılık halinde
        işleme sürecini durduracaktır.
      </p>
      <h3>Veri Koruma İlkeleri</h3>
      <p>
        Kişisel Verilerin Korunması Kanunu’nun (“KVKK”) 4. Maddesinde sayılmış
        olup, aşağıdaki gibidir:
      </p>
      <ol>
        <li>
          Kişisel veriler, ancak bu Kanunda ve diğer kanunlarda öngörülen usul
          ve esaslara uygun olarak işlenebilir.
        </li>
        <li>
          Kişisel verilerin işlenmesinde aşağıdaki ilkelere uyulması zorunludur:
          <ol type="a">
            <li>Hukuka ve dürüstlük kurallarına uygun olma.</li>
            <li>Doğru ve gerektiğinde güncel olma.</li>
            <li>Belirli, açık ve meşru amaçlar için işlenme.</li>
            <li>İşlendikleri amaçla bağlantılı, sınırlı ve ölçülü olma.</li>
            <li>
              İlgili mevzuatta öngörülen veya işlendikleri amaç için gerekli
              olan süre kadar muhafaza edilme.
            </li>
          </ol>
        </li>
      </ol>
      <h2>VERBİS</h2>
      <p>
        İşbu Politika’nın yayım tarihinde Şirket VERBİS sorumlusu değildir. Veri
        Sorumluluları Sicili Hakkında Yönetmelik 8(2) kapsamında, (2) Kayıt
        yükümlülüğü altında bulunmayan, sonradan kayıt yükümlüsü haline gelen
        veri sorumluları, yükümlülük altına girmelerini müteakip otuz gün
        içerisinde Sicile kaydolurlar. Şirket, düzenli aralıklarla kayıt
        yükümlülüğü için gerekli şartları inceler ve kayıt yükümlülüğünün ortaya
        çıktığı tarihten itibaren 30 (otuz) gün içinde Sicile kaydolur.
      </p>
      <h3>4. VERİ SAHİPLERİNİN KANUNİ HAKLARI</h3>
      <p>
        Veri sahipleri her zaman, aşağıda sıralanan haklarını Şirket’e karşı
        kullanabilir:
      </p>
      <ol>
        <li>Kişisel veri işlenip işlenmediğini öğrenme,</li>
        <li>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</li>
        <li>
          Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp
          kullanılmadığını öğrenme,
        </li>
        <li>
          Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü
          kişileri bilme,
        </li>
        <li>
          Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların
          düzeltilmesini isteme,
        </li>
        <li>
          Hukuki sebep olmadan işlenen verilerin/ açık rıza kapsamında işlenen
          kişisel verilerin silinmesini veya yok edilmesini isteme,
        </li>
        <li>
          Silme ve imha işlemlerinin, kişisel verilerin aktarıldığı üçüncü
          kişilere bildirilmesini isteme,
        </li>
        <li>
          İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz
          edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya
          çıkmasına itiraz etme,
        </li>
        <li>
          Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara
          uğraması hâlinde zararın giderilmesini talep etme
        </li>
      </ol>
      <p>
        Şirket, veri sahibi başvurularını en geç 30 gün içerisinde
        cevaplandıracaktır. Bu süreç için “Şirket Talep Yönetim Prosedürü”
        hazırlanmıştır. Veri sahipleri taleplerini, Veri Sahibi Başvuru Formunu
        doldurarak Şirket’e kvkk@alohalive.online adresinden iletebilir. İşlemin
        ayrıca bir maliyet gerektirmesi halinde Kişisel Verileri Koruma Kurulu
        tarafından belirlenecek tarifeye göre ücret talebi gündeme gelebilir.
      </p>
      <h3>5. AÇIK RIZA SÜREÇLERİ</h3>
      <p>
        Şirket nezdinde, işleme faaliyetleri mümkün olduğunca, açık rıza
        dışındaki hukuki sebeplere dayandırılmaktadır. Yalnızca, kullanıcılara
        yönelik pazarlama süreçleri ve yurtdışından hizmet alınması hallerinde
        oluşacak veri aktarımları için açık rızaya başvurulmaktadır. Şirket,
        açık rızayı kullanıcılar için elektronik onay vasıtasıyla, diğer kişiler
        için formlar yoluyla alır. Açık rıza formları veri sorumlusunun KVKK
        izin dosyasında ispat için saklanır. Rıza beyanları rızanın geri
        çekildiği tarihten itibaren üç yıl süreyle saklanır.
      </p>
      <h3>6. VERİ İŞLEYEN VE DİĞER VERİ SORUMLULARI İLE SÜREÇLER</h3>
      <p>
        İş faaliyetleri kapsamında zaman zaman üçüncü kişilerle veri paylaşımı
        söz konusu olmaktadır. Aktarım, bir veri işlemedir. Şirket yalnızca
        Kanun’un 5. Maddesinde sıralanan ve aşağıda belirtilen şartlardan
        birinin/birden fazlasının mevcudiyeti halinde veriyi 3.kişilerle
        paylaşır.
      </p>
      <ol>
        <li>
          Kişisel veriler ilgili kişinin açık rızası olmaksızın işlenemez.
        </li>
        <li>
          Aşağıdaki şartlardan birinin varlığı hâlinde, ilgili kişinin açık
          rızası aranmaksızın kişisel verilerinin işlenmesi mümkündür:
          <ol type="a">
            <li>Kanunlarda açıkça öngörülmesi.</li>
            <li>
              Fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda
              bulunan veya rızasına hukuki geçerlilik tanınmayan kişinin
              kendisinin ya da bir başkasının hayatı veya beden bütünlüğünün
              korunması için zorunlu olması.
            </li>
            <li>
              Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili
              olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin
              işlenmesinin gerekli olması.
            </li>
            <li>
              Veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için
              zorunlu olması.
            </li>
            <li>İlgili kişinin kendisi tarafından alenileştirilmiş olması.</li>
            <li>
              Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin
              zorunlu olması.
            </li>
            <li>
              İlgili kişinin temel hak ve özgürlüklerine zarar vermemek
              kaydıyla, veri sorumlusunun meşru menfaatleri için veri
              işlenmesinin zorunlu olması.
            </li>
          </ol>
        </li>
      </ol>
      <h2>
        7. Şirket’in Veri İşleme Süreçlerine İlişkin Ayrıntılı Açıklamalar
      </h2>
      <h3>7.1. Kişisel Veri İşleme Amaçları</h3>
      <p>
        <strong>
          Uygulama Kullanıcıları (Konuşmacı&Dinleyici Özelinde Hazırlanmıştır,
          İç Süreçlere İlişkin Kurallar İç Platformlarda Yayıma Alınacaktır.)
        </strong>
      </p>
      <p>
        <strong>
          Hizmetler’den Konuşmacı Girişini Kullanan Uygulama Kullanıcılarının
          Verileri
        </strong>
      </p>
      <h4>Üyelik Kaydı İçin Toplanan Veriler</h4>
      <p>
        <strong>
          İşleme Amacını Gösteren Ana Süreçlerde, Veri Sorumluları Bilgi Sicil
          Sistemi Sınıflandırmaları Kullanılmıştır. Şirketimiz, işbu metnin
          yayınlandığı tarihte, VERBİS sorumlusu değildir; ancak veri
          envanterleri oluşturulmuş, mevzuat kapsamındaki tüm önlemler
          alınmıştır.
        </strong>
      </p>
      <ul>
        <li>
          <strong>Kimlik verilerinden:</strong> kullanıcı adı (konuşmacının
          gerçek ismini kullandığı hallerde) Kullanıcılar gerçek ismini
          kullanmak zorunda değildir. Aloha, sohbetlere anonim katılımı
          önermektedir.
        </li>
        <p>
          Bu kategoride sıralanan veriler, “Bir sözleşmenin kurulması veya
          ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin
          taraflarına ait kişisel verilerin işlenmesinin gerekli olması” ve
          İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla,
          veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu
          olması hukuki sebebi kapsamında, Mal / Hizmet Satış Süreçlerinin
          Yürütülmesi, Mal / Hizmet Üretim ve Operasyon Süreçlerinin Yürütülmesi
          ve İletişim Faaliyetlerinin Yürütülmesi, Sözleşme Süreçlerinin
          Yürütülmesi, Saklama Ve Arşiv Faaliyetlerinin Yürütülmesi,
          Faaliyetlerin Mevzuata Uygun Yürütülmesi ana sürecinde; üyelik
          kaydı/başvuru alınması, kullanıcı ve dinleyici arasındaki iletişimin
          kurulması, öneri, şikayet ve soruları kayıt altına alabilmek,
          sözleşmelerin akdedilmesi ve güncellenmesi, arşivlenmesi amaçlarıyla
          işlenmektedir.
        </p>
        <li>
          <strong>İletişim verilerinden:</strong> elektronik posta adresi
        </li>
        <p>
          Bu kategoride sıralanan veriler, “İlgili kişinin temel hak ve
          özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru
          menfaatleri için veri işlenmesinin zorunlu olması”hukuki sebebi
          kapsamında, Mal / Hizmet Üretim ve Operasyon Süreçlerinin Yürütülmesi
          ve İletişim Faaliyetlerinin Yürütülmesi, Sözleşme Süreçlerinin
          Yürütülmesi, Saklama ve Arşiv Faaliyetlerinin Yürütülmesi, Bilgi
          Güvenliği Süreçlerinin Yürütülmesi, Veri Sorumlusu Operasyonlarının
          Güvenliğinin Temini ana süreçlerinde, üyelik ve sözleşme güncelleme
          süreçlerinde kimlik doğrulama süreçlerinin icrası, Şirket ve
          dinleyicilerle olan iletişimin sağlanması, ticari iletişim süreçleri,
          öneri, şikayet ve soruları kayıt altına alabilmek, sözleşmelerin
          akdedilmesi ve güncellenmesi, arşivlenmesi amaçlarıyla işlenmektedir.
        </p>
      </ul>
      <h4>Hizmetlerin Kullanılması Sırasında İşlenen Veriler</h4>
      <ul>
        <li>
          <strong>İşlem güvenliği verilerinden:</strong> Hizmetler’in görülmesi
          esnasında veya Hizmetler için yapılan her türlü yazışma vb., sayfada
          gezinme ve/veya kullanım oturumuna ait veriler (IP adresi, coğrafi
          konum, benzersiz cihaz tanımlayıcı vb., Uygulama üzerindeki
          faaliyetlere ilişkin veriler, şifre verisi
        </li>
        <p>
          Bu kategoride sıralanan verileriniz, “İlgili kişinin temel hak ve
          özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru
          menfaatleri için veri işlenmesinin zorunlu olması”hukuki sebebi
          kapsamında, Saklama ve Arşiv Faaliyetlerinin Yürütülmesi, Bilgi
          Güvenliği Süreçlerinin Yürütülmesi, Veri Sorumlusu Operasyonlarının
          Güvenliğinin Temini amaçlarıyla işlenmektedir.
        </p>
      </ul>
      <h4>Konuşmacı Kartı Oluşturulması</h4>
      <p>
        Konuşmacı’nın, kimliğini belirlemeye yarayan verileri sisteme kaydı
        zorunlu değildir. Konuşmacı, ayırt edici kişisel bilgilerini kendi
        isteğiyle Dinleyici’ye bildirirse, bu bilgiler, Aloha Kişisel Verilerin
        Korunması Politikasına ve işbu Aydınlatma Metnine uygun şekilde
        işlenecek ve arşivlenecektir.
      </p>
      <p>
        Konuşmacı’ya, ilettiği bilgilere göre bir kullanıcı kartı düzenlenir.
        Konuşmacı kartında, temel olarak; yaş, cinsiyet, duygu durumu, kullanıcı
        adı, sohbet dili, ilgilendiği konular ve sohbet süresi yer alır.
        Konuşmacı, tercihine göre cinsiyet, yaş sekmelerini boş bırakabilir.
        Kullanıcı, dinleyici ile konuşması sırasında bu bilgilerinin gizlenmesi
        için düzenleme yapabilir.
      </p>
      <h2>Bu süreçte;</h2>
      <ul>
        <li>
          <strong>Kimlik verilerinden;</strong>
          <ul>
            <li>
              kullanıcı adı (gerçek ismini kullandığı hallerde) Konuşmacılar
              gerçek ismini kullanmak zorunda değildir. Aloha, sohbetlere anonim
              katılımı önermektedir.
            </li>
            <li>yaş</li>
            <li>cinsiyet</li>
          </ul>
          <p>
            Bu kategoride sıralanan veriler, “Bir sözleşmenin kurulması veya
            ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin
            taraflarına ait kişisel verilerin işlenmesinin gerekli olması” ve
            İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla,
            veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu
            olması hukuki sebebi kapsamında, Mal / Hizmet Satış Süreçlerinin
            Yürütülmesi, Mal / Hizmet Üretim ve Operasyon Süreçlerinin
            Yürütülmesi ve İletişim Faaliyetlerinin Yürütülmesi, Sözleşme
            Süreçlerinin Yürütülmesi, Saklama Ve Arşiv Faaliyetlerinin
            Yürütülmesi ana sürecinde; üyelik kaydı/başvuru alınması, konuşmacı
            ve dinleyici arasındaki iletişimin kurulması, öneri, şikayet ve
            sorularınızı kayıt altına alabilmek, sözleşmelerin akdedilmesi ve
            güncellenmesi ve arşivlenmesi ile sözleşmeyi gereği gibi ifa
            edebilmek amaçlarıyla işlenmektedir.
          </p>
        </li>
        <li>
          <strong>Diğer Verilerinden;</strong>
          <p>
            Hizmetler İçin Zorunlu Olanlar “Z” ile işaretlenmiştir. Sıralanan
            verilerden zorunlu olanlar dışındakileri paylaşmak istemiyorsanız
            “Bu Adımı Geç” butonuna tıklayınız. Paylaşmanız halinde, işleme
            süreçleri aşağıda açıklandığı şekilde yürütülecektir.
          </p>
          <ul>
            <li>duygu durumu</li>
            <li>sohbet süresi (Z)</li>
            <li>favorilerine aldığı dinleyici sayısı (Z)</li>
            <li>ilgilendiği konular (Z)</li>
            <li>feedback (geri dönüş yorumları) sekmesinde işlenen veriler</li>
            <li>dinleyici değerlendirmeleri</li>
          </ul>
        </li>
        <li>
          <strong>Mesleki Deneyim Verilerinden;</strong>
          <ul>
            <li>meslek (Konuşmacı, meslek verisini boş bırakabilir.)</li>
          </ul>
          <p>
            Bu kategoride sıralanan veriler, İlgili kişinin temel hak ve
            özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru
            menfaatleri için veri işlenmesinin zorunlu olması ve sözleşmenin
            ifasıyla doğrudan doğruya ilgili olması hukuki sebebi kapsamında;
            Mal / Hizmet Satış Süreçlerinin Yürütülmesi, Mal / Hizmet Üretim ve
            Operasyon Süreçlerinin Yürütülmesi ve İletişim Faaliyetlerinin
            Yürütülmesi, Sözleşme Süreçlerinin Yürütülmesi, Saklama Ve Arşiv
            Faaliyetlerinin Yürütülmesi, konuşmacı ve dinleyici arasındaki
            iletişimin kurulması, sözleşmenin gereği gibi ifasının sağlanması,
            öneri, şikayet ve sorularınızı kayıt altına alabilmek, dinleyici
            eğitimlerinin konuşmacı kitlesine göre güncelliğinin korunması,
            sözleşmelerin akdedilmesi ve güncellenmesi, arşivlenmesi amaçlarıyla
            işlenmektedir.
          </p>
        </li>
        <li>
          <strong>Müşteri İşlem Verilerinden;</strong>
          <ul>
            <li>
              Satın alınan ve kullanılan ALC Coin sayısı, satın alma tarihi ve
              miktarı, bakiye bilgileri, sözleşme ve onay metinleri. !
              Kullanıcı’nın kredi kartı/banka kartı bilgilerine ALOHA erişemez.
              Ödeme altyapısı Play Store ve App Store tarafından sağlanmaktadır.
            </li>
          </ul>
          <p>
            Bu kategoride sıralanan veriler, “Bir sözleşmenin kurulması veya
            ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin
            taraflarına ait kişisel verilerin işlenmesinin gerekli olması” ve
            İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla,
            veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu
            olması hukuki sebebi kapsamında, Mal / Hizmet Satış Süreçlerinin
            Yürütülmesi, Mal / Hizmet Üretim ve Operasyon Süreçlerinin
            Yürütülmesi, Sözleşme Süreçlerinin Yürütülmesi, Finans Ve Muhasebe
            İşlerinin Yürütülmesi, Saklama Ve Arşiv Faaliyetlerinin Yürütülmesi,
            Faaliyetlerin Mevzuata Uygun Yürütülmesi ana sürecinde; kullanım
            haklarının tespiti ve takibi ve tanıtım faaliyetleri, iletişim
            faaliyetlerinin yönetilmesi amacıyla işlenmektedir.
          </p>
        </li>
        <li>
          <strong>
            Şikâyet, Talep ve Anketler Vasıtasıyla İşlenen Veriler
          </strong>
          <ul>
            <li>
              <strong>Müşteri İşlem Verilerinden;</strong>
              <ul>
                <li>Şikâyet veya talebin tarihi ve saati</li>
                <li>Anket cevapları</li>
                <li>Şikâyet veya talebin mahiyeti ve sebebi</li>
                <li>Şikâyet edilen kullanıcıya ilişkin veriler</li>
              </ul>
            </li>
            <li>
              <strong>Kimlik verilerinden;</strong>
              <ul>
                <li>
                  Şikâyet konusunun resmi mercilere haber verilmesini gerektirir
                  olması halinde, kimliği kanıtlayan bir belge
                </li>
                <li>
                  Kişisel Verilerinizin Korunması Kanununun 11. Maddesi
                  kapsamında yapılan başvurularda kimliği kanıtlayan belge
                </li>
                <li>
                  Hukuki uyuşmazlık süreçlerinin gündeme gelmesi halinde kimliği
                  kanıtlayan belge
                </li>
              </ul>
            </li>
          </ul>
          <p>
            Bu kategorilerde sıralanan verileriniz, “İlgili kişinin temel hak ve
            özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru
            menfaatleri için veri işlenmesinin zorunlu olması ve Bir hakkın
            tesisi, kullanılması veya korunması için veri işlemenin zorunlu
            olması hukuki sebebi kapsamında, Mal / Hizmet Üretim ve Operasyon
            Süreçlerinin Yürütülmesi ve İletişim Faaliyetlerinin Yürütülmesi,
            Sözleşme Süreçlerinin Yürütülmesi, Saklama ve Arşiv Faaliyetlerinin
            Yürütülmesi, Faaliyetlerin Mevzuata Uygun Yürütülmesi, Yetkili Kamu
            Kurum ve Kuruluşlara bilgi verilmesi ana sürecinde; öneri, şikayet
            ve soruları kayıt altına alabilmek, yetkili kurum ve kuruluşlara
            bilgi verebilmek, kimlik doğrulaması yapabilmek, hukuka
            aykırılıkları önlemek ve gidermek ve ilgili işlemlerin arşivlenmesi
            amaçlarıyla işlenmektedir.
          </p>
        </li>
      </ul>
      <h2>İşlenen Verilerden Yalnızca Dinleyici Sıfatını Taşıyanlar İçin</h2>
      <h3>Üyelik Kaydı İçin İşlenen Veriler</h3>
      <ul>
        <li>
          <strong>Kimlik verilerinden;</strong> kullanıcı adı, ad-soyad
          (Dinleyiciler Uygulama’ya anonim katılabilirler, gerçek isimlerini
          bildirmeleri zorunlu değildir)
          <ul>
            <li>doğum tarihi (Dinleyici tarafından bildirilmesi halinde)</li>
          </ul>
          <p>
            Bu kategoride sıralanan veriler, “Bir sözleşmenin kurulması veya
            ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin
            taraflarına ait kişisel verilerin işlenmesinin gerekli olması” ve
            İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla,
            veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu
            olması hukuki sebebi kapsamında, Mal / Hizmet Üretim Ve Operasyon
            Süreçlerinin Yürütülmesi, Mal / Hizmet Satın Alım ve Satış
            Süreçlerinin Yürütülmesi, İletişim Faaliyetlerinin Yürütülmesi,
            Sözleşme Süreçlerinin Yürütülmesi, Saklama Ve Arşiv Faaliyetlerinin
            Yürütülmesi, Faaliyetlerin Mevzuata Uygun Yürütülmesi, Finans ve
            Muhasebe İşlerinin Yürütülmesi ana sürecinde; dinleyicilik sıfatının
            kazanılması, dinleyici ve konuşmacılar arasındaki iletişimin
            kurulması, sözleşmelerin akdedilmesi ve güncellenmesi, arşivlenmesi,
            faturalama ve arşivleme işlemlerinin yürütülmesi, veri sorumlusu
            operasyonlarının güvenliğinin temini, performans değerlendirme
            süreçlerinin yürütülmesi, öneri, şikayet ve soruları kayıt altına
            alabilmek amaçlarıyla işlenmektedir.
          </p>
        </li>
        <li>
          <strong>İletişim verilerinden;</strong> elektronik posta adresi
          <p>
            Bu kategoride sıralanan veriler, “İlgili kişinin temel hak ve
            özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru
            menfaatleri için veri işlenmesinin zorunlu olması” hukuki sebebi
            kapsamında, Mal / Hizmet Üretim Ve Operasyon Süreçlerinin
            Yürütülmesi ve İletişim Faaliyetlerinin Yürütülmesi, Sözleşme
            Süreçlerinin Yürütülmesi, Saklama Ve Arşiv Faaliyetlerinin
            Yürütülmesi, Bilgi Güvenliği Süreçlerinin Yürütülmesi, Veri
            Sorumlusu Operasyonlarının Güvenliğinin Temini ana süreçlerinde,
            üyelik ve sözleşme güncelleme süreçlerinde kimlik doğrulama
            süreçlerinin icrası, Şirket ve konuşmacılarla olan iletişiminizin
            sağlanması, ticari iletişim süreçleri, öneri, şikayet ve soruları
            kayıt altına alabilmek, sözleşmelerin akdedilmesi ve güncellenmesi,
            arşivlenmesi amaçlarıyla işlenmektedir.
          </p>
        </li>
        <li>
          <strong>Diğer verilerden;</strong> Dinleyiciler için, online eğitim
          tamamlama bilgisi.
          <p>
            Bu kategoride sıralanan veriler, “İlgili kişinin temel hak ve
            özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru
            menfaatleri için veri işlenmesinin zorunlu olması” hukuki sebebi
            kapsamında, Eğitim Faaliyetlerinin Yürütülmesi ve Sözleşme
            Süreçlerinin Yürütülmesi amaçlarıyla işlenmektedir.
          </p>
        </li>
      </ul>
      <h3>Hizmetlerin Kullanılması Sırasında İşlenen Kişisel Veriler</h3>
      <h4>Dinleyici Kartı Oluşturulması</h4>
      <p>
        Dinleyici adayı; ad, soyad, kullanıcı adı, doğum tarihi, elektronik
        posta adresi ve şifre bilgilerini doldurarak ve sohbet konusunu seçerek
        sisteme kaydolur. Dinleyici, Aloha tarafından verilen eğitim ve onay
        sonrası dinleyici sıfatını kazanır ve kendisine Uygulama için kullanıcı
        adı ve şifre verilir ve dinleyici kartı oluşturulur.
      </p>
      <p>
        Dinleyici kartında, dinleyicinin; yaş, cinsiyet, duygu durumu, sohbet
        dili, uygulamaya kaç aydır üye olduğu, kullanıcı adı, kaç konuşmacının
        kendisine favorilerine eklediği, aktif edilmesi halinde hakkındaki
        yorumlar ve puan bilgisi yer alır.
      </p>
      <h4>Bu süreçte;</h4>
      <ul>
        <li>
          <strong>Kimlik verilerinden;</strong>
          <ul>
            <li>kullanıcı adı (Z)</li>
            <li>yaş</li>
            <li>cinsiyet verileri işlenir.</li>
          </ul>
          <p>
            Bu kategoride sıralanan veriler, “Bir sözleşmenin kurulması veya
            ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin
            taraflarına ait kişisel verilerin işlenmesinin gerekli olması” ve
            İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla,
            veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu
            olması hukuki sebebi kapsamında, Mal / Hizmet Satış Süreçlerinin
            Yürütülmesi, Mal / Hizmet Üretim ve Operasyon Süreçlerinin
            Yürütülmesi ve İletişim Faaliyetlerinin Yürütülmesi, Sözleşme
            Süreçlerinin Yürütülmesi, Saklama Ve Arşiv Faaliyetlerinin
            Yürütülmesi, Faaliyetlerin Mevzuata Uygun Yürütülmesi ana sürecinde;
            üyelik kaydınızın/başvurunuzun alınması, konuşmacı ve dinleyici
            arasındaki iletişimin kurulması, öneri, şikayet ve sorularınızı
            kayıt altına alabilmek, sözleşmenin gereği gibi ifası, sözleşmelerin
            akdedilmesi ve güncellenmesi, arşivlenmesi amaçlarıyla
            işlenmektedir.
          </p>
        </li>
        <li>
          <strong>Diğer Verilerinden;</strong>
          <ul>
            <li>duygu durumu</li>
            <li>dinleyiciyi favorilerine alan konuşmacı sayısı</li>
            <li>ilgilendiği konular</li>
            <li>feedback (geri dönüş yorumları) sekmesinde işlenen veriler</li>
            <li>konuşmacı değerlendirmeleri</li>
          </ul>
        </li>
        <li>
          <strong>Mesleki Deneyim Verilerinden;</strong>
          <ul>
            <li>meslek</li>
          </ul>
          <p>
            Bu kategorilerde sıralanan veriler, İlgili kişinin temel hak ve
            özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru
            menfaatleri için veri işlenmesinin zorunlu olması hukuki sebebi
            kapsamında, Mal / Hizmet Satış Süreçlerinin Yürütülmesi, Mal /
            Hizmet Üretim ve Operasyon Süreçlerinin Yürütülmesi ve İletişim
            Faaliyetlerinin Yürütülmesi, Sözleşme Süreçlerinin Yürütülmesi,
            Saklama ve Arşiv Faaliyetlerinin Yürütülmesi, konuşmacı ve dinleyici
            arasındaki iletişimin kurulması, öneri, şikayet ve sorularınızı
            kayıt altına alabilmek, sözleşmelerin akdedilmesi ve güncellenmesi,
            arşivlenmesi amaçlarıyla işlenmektedir.
          </p>
        </li>
      </ul>
      <h3>Şikâyet, Talep ve Anketler Vasıtasıyla İşlenen Veriler</h3>
      <ul>
        <li>
          <strong>Müşteri İşlem Verilerinden;</strong>
          <ul>
            <li>Şikâyet veya talebin tarihi ve saati</li>
            <li>Anket cevapları</li>
            <li>Şikâyet veya talebin mahiyeti ve sebebi</li>
            <li>Şikâyet edilen konuşmacıya ilişkin veriler</li>
          </ul>
        </li>
        <li>
          <strong>Kimlik verilerinden;</strong>
          <ul>
            <li>
              Şikâyet konusunun resmi mercilere haber verilmesini gerektirir
              olması halinde, kimliği kanıtlayan bir belge
            </li>
            <li>
              Kişisel Verilerinizin Korunması Kanununun 11. Maddesi kapsamında
              yapılan başvurularda kimliği kanıtlayan belge
            </li>
            <li>
              Hukuki uyuşmazlık süreçlerinin gündeme gelmesi halinde kimliği
              kanıtlayan belge
            </li>
          </ul>
          <p>
            Bu kategoride sıralanan veriler, “İlgili kişinin temel hak ve
            özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru
            menfaatleri için veri işlenmesinin zorunlu olması hukuki sebebi
            kapsamında, Mal / Hizmet Üretim ve Operasyon Süreçlerinin
            Yürütülmesi ve İletişim Faaliyetlerinin Yürütülmesi, Sözleşme
            Süreçlerinin Yürütülmesi, Saklama Ve Arşiv Faaliyetlerinin
            Yürütülmesi, Faaliyetlerin Mevzuata Uygun Yürütülmesi, Yetkili Kamu
            Kurum ve Kuruluşlara bilgi verilmesi ana sürecinde; öneri, şikâyeti
            ve soruları kayıt altına alabilmek, yetkili kurum ve kuruluşlara
            bilgi verebilmek hukuka aykırılıkları önlemek ve gidermek ve ilgili
            işlemlerin arşivlenmesi amaçlarıyla işlenmektedir.
          </p>
        </li>
      </ul>
      <h2>
        Görsel ve İşitsel Veri Kategorisinden İşitsel Veriler (Konuşmacı,
        Dinleyici)
      </h2>
      <p>
        Uygulama üzerinde sesli sohbet seçeneği bulunmaktadır. Ancak
        kullanıcıların sesi Uygulama’da herhangi bir şekilde tanımlama veya
        doğrulama işlemine tabi tutulmaz. Bu nedenle özel nitelikli kişisel veri
        kategorisinde değil, bu kategoride değerlendirilmiştir. Uygulama
        nezdinde sesli sohbetler uçtan uca şifrelenerek saklanır.
      </p>
      <p>
        Bu kategoride sıralanan veriler, “İlgili kişinin temel hak ve
        özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru
        menfaatleri için veri işlenmesinin zorunlu olması” hukuki sebebi
        kapsamında, İletişim Faaliyetlerinin Yürütülmesi, Sözleşme Süreçlerinin
        Yürütülmesi amacıyla işlenmektedir.
      </p>
      <h2>Diğer Kategorisindeki Verilerden (Konuşmacı, Dinleyici)</h2>
      <h4>Sohbet Verileri</h4>
      <p>
        İletişimin gizliliği esas olmakla birlikte acil durum bildirimi (taciz,
        hakaret içerikli yazışma, uygunsuz sohbet içeriğini, uzun süredir cevap
        alamama bildirimi, diğer) halinde, Dinleyici’nin şikâyeti üzerine, yahut
        gerekli görülen hallerde, admin sohbete davet edilebilir. Admin, sohbeti
        ilk adımda anonim olarak kontrol edecek, Sözleşme’ye yahut mevzuata
        aykırı bir durum varsa, ilgili bilgileri Şirket yetkililerine kişisel
        verileri de içerecek şekilde aktaracaktır. Admin, bu ziyareti görünmeden
        yapabilir veya Dinleyici ile ayrı bir ekranda iletişim kurabilir.
        Dinleyici, Aloha sistem yöneticisi veya görevlileri tarafından
        iletişimin denetlenmesi ve yasaya aykırılık şüphesi olması durumunda,
        iletişimin bir kopyasının saklanmasını, talep halinde resmi kurum ve
        kuruluşlarla paylaşılmasını kabul eder.
      </p>
      <p>
        Yazılı mesajlar ve sesli mesajlar tamamıyla özeldir ve sadece ilgili
        kullanıcıları ilgilendirir. Ancak, mesajlar, adli talep ve/veya yasal
        düzenlemeler kapsamında verilerin saklanması ve yetkili kamu kurum ve
        kuruluşlarla paylaşılması gündeme gelebilir. Sohbet içerikleri uçtan uça
        şifrelenmektedir.
      </p>
      <p>
        Bu kapsamda, bu kategoride sıralanan veriler, “İlgili kişinin temel hak
        ve özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru
        menfaatleri için veri işlenmesinin zorunlu olması” hukuki sebebi
        kapsamında, Mal / Hizmet Üretim ve Operasyon Süreçlerinin Yürütülmesi ve
        İletişim Faaliyetlerinin Yürütülmesi, Sözleşme Süreçlerinin Yürütülmesi,
        Saklama Ve Arşiv Faaliyetlerinin Yürütülmesi, Faaliyetlerin Mevzuata
        Uygun Yürütülmesi, Yetkili Kamu Kurum ve Kuruluşlara bilgi verilmesi ana
        sürecinde; şikayetleri kayıt altına alabilmek, yetkili kurum ve
        kuruluşlara bilgi verebilmek hukuka aykırılıkları önlemek ve gidermek ve
        ilgili işlemlerin arşivlenmesi amaçlarıyla işlenmektedir.
      </p>
      <h2>Pazarlama Verilerinden</h2>
      <p>
        Alışveriş geçmişi, çerez kayıtları, anket kayıtları, kampanya
        çalışmaları ile elde edilen veriler.
      </p>
      <h2>Görsel ve İşitsel Veri Kategorisinden</h2>
      <h3>İşitsel Veriler (Konuşmacı, Dinleyici)</h3>
      <p>
        Uygulama üzerinde sesli sohbet seçeneği bulunmaktadır. Ancak
        kullanıcıların sesi Uygulama’da herhangi bir şekilde tanımlama veya
        doğrulama işlemine tabi tutulmaz. Bu nedenle özel nitelikli kişisel veri
        kategorisinde değil, bu kategoride değerlendirilmiştir. Uygulama
        nezdinde sesli sohbetler uçtan uca şifrelenerek saklanır.
      </p>
      <p>
        Bu kategoride sıralanan veriler, “İlgili kişinin temel hak ve
        özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru
        menfaatleri için veri işlenmesinin zorunlu olması” hukuki sebebi
        kapsamında, İletişim Faaliyetlerinin Yürütülmesi, Sözleşme Süreçlerinin
        Yürütülmesi amacıyla işlenmektedir.
      </p>
      <h3>Diğer Kategorisindeki Verilerden (Konuşmacı, Dinleyici)</h3>
      <h4>Sohbet Verileri</h4>
      <p>
        İletişimin gizliliği esas olmakla birlikte acil durum bildirimi (taciz,
        hakaret içerikli yazışma, uygunsuz sohbet içeriğini, uzun süredir cevap
        alamama bildirimi, diğer) halinde, Dinleyici’nin şikâyeti üzerine, yahut
        gerekli görülen hallerde, admin sohbete davet edilebilir. Admin, sohbeti
        ilk adımda anonim olarak kontrol edecek, Sözleşme’ye yahut mevzuata
        aykırı bir durum varsa, ilgili bilgileri Şirket yetkililerine kişisel
        verileri de içerecek şekilde aktaracaktır. Admin, bu ziyareti görünmeden
        yapabilir veya Dinleyici ile ayrı bir ekranda iletişim kurabilir.
        Dinleyici, Aloha sistem yöneticisi veya görevlileri tarafından
        iletişimin denetlenmesi ve yasaya aykırılık şüphesi olması durumunda,
        iletişimin bir kopyasının saklanmasını, talep halinde resmi kurum ve
        kuruluşlarla paylaşılmasını kabul eder.
      </p>
      <p>
        Yazılı mesajlar ve sesli mesajlar tamamıyla özeldir ve sadece ilgili
        kullanıcıları ilgilendirir. Ancak, mesajlar, adli talep ve/veya yasal
        düzenlemeler kapsamında verilerin saklanması ve yetkili kamu kurum ve
        kuruluşlarla paylaşılması gündeme gelebilir. Sohbet içerikleri uçtan uça
        şifrelenmektedir.
      </p>
      <h3>Çerez Verileri</h3>
      <p>
        Çerezlerin türüne göre, işlemede kullanım amacı ve hukuki sebebi
        değişmektedir. Ayrıntılı bilgi için web sitemiz üzerinden Çerez
        Aydınlatma Metnine ulaşabilirsiniz.
      </p>
      <p>
        Bu kategoride sıralanan veriler, açık rızanız kapsamında,
        <ul>
          <li>Denetim / Etik Faaliyetlerinin Yürütülmesi</li>
          <li>Faaliyetlerin Mevzuata Uygun Yürütülmesi</li>
          <li>Firma / Ürün / Hizmetlere Bağlılık Süreçlerinin Yürütülmesi</li>
          <li>
            İç Denetim/ Soruşturma / İstihbarat Faaliyetlerinin Yürütülmesi
          </li>
          <li>İletişim Faaliyetlerinin Yürütülmesi</li>
          <li>İş Faaliyetlerinin Yürütülmesi / Denetimi</li>
          <li>Müşteri İlişkileri Yönetimi Süreçlerinin Yürütülmesi</li>
          <li>Müşteri Memnuniyetine Yönelik Aktivitelerin Yürütülmesi</li>
          <li>Organizasyon Ve Etkinlik Yönetimi</li>
          <li>Pazarlama Analiz Çalışmalarının Yürütülmesi</li>
          <li>Reklam / Kampanya / Promosyon Süreçlerinin Yürütülmesi</li>
          <li>Ürün / Hizmetlerin Pazarlama Süreçlerinin Yürütülmesi</li>
        </ul>
        ana süreçlerinde ticari iletişim ve pazarlama çalışmalarının yönetilmesi
        amacıyla işlenmektedir.
      </p>
      <DataTable />
      <DataRetentionTable />
    </div>
  );
};

export default Privacy;
