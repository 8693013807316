import React from "react";

const UserAgreement = () => {
  return (
    <div id="userAgreement" className="privacy-container">
      <h1>HİZMET ŞARTLARI</h1>
      <p>
        LÜTFEN İŞBU HİZMET ŞARTLARINI DİKKATLİCE OKUYUNUZ. HİZMET ŞARTLARI,
        HİZMETİ KULLANIMINIZA İLİŞKİN YASAL OLARAK BAĞLAYICI HÜKÜM VE ŞARTLARI
        İÇERMEKTEDİR. HİZMETLERİMİZİ KULLANMAK İSTEMENİZ DURUMUNDA İŞBU HİZMET
        ŞARTLARINI KABUL ETMENİZ GEREKMEKTEDİR. HİZMET ŞARTLARINI KABUL
        ETMEZSENİZ HİZMETİ KULLANMA HAKKINIZ BULUNMAYACAK OLUP SÜRECE DEVAM
        ETMEMENİZ GEREKMEKTEDİR.
      </p>
      <h2>1. TARAFLAR VE SÖZLEŞMENİN YÜRÜRLÜĞE GİRMESİ</h2>
      <p>
        0055169394100001 MERSİS ve 297461-5 numaralı, tescilli adresi Barbaros
        Mahallesi Begonya Sokak Nidakule Ataşehir Batı Blok No:1 İç Kapı No:2
        Ataşehir İstanbul olan, Aloha veya zaman zaman kullanılabilecek herhangi
        bir ticari unvan ile faaliyet göstermekte olan Aloha Dijital Bilişim
        Eğitim ve Danışmanlık Anonim Şirketi (“Aloha”, “Biz”, “Bize”, “Bizim”)
        tarafından sağlanan Hizmetler’in (aşağıda tanımlandığı üzere) herhangi
        birinin Tarafınızca kullanılmasıyla ilgili olarak Aloha açısından Sizin
        (“Kullanıcı”, “Siz”, “Sizin”), işbu Hizmet Şartlarında (“Sözleşme”) yer
        alan tüm hüküm ve şartları kabul etmeniz gerekmektedir.
      </p>
      <p>
        Bu Sözleşme hükümlerini kabul etmek için tıkladığınızda Sözleşme’nin
        yasal olarak bağlayıcılığını kabul etmiş olmaktasınız. Sözleşme’yi ve
        burada yer alan şartları kabul etmiyorsanız, lütfen Hizmetler’i
        kullanmayınız. Kabul onayını verdiğiniz anda işbu Sözleşme yürürlüğe
        girmektedir.
      </p>
      <p>
        İşbu Sözleşme, Gizlilik Politikamızı ve zaman zaman yürürlüğe
        koyabileceğimiz tüm güncellemeleri, ekleri, ek şartları, ek kuralları ve
        politikaları içermektedir. Tüm bu belgeler hep birlikte (Sizin ve Bizim)
        aramızdaki anlaşmayı oluşturmaktadır. Kişisel Verileri Koruma
        Politikamız, Size ait bilgileri nasıl kullandığımızı açıklamaktadır.
        Kişisel Verileri Koruma Politikamızı okumanız gerekmekte olup ilgili
        Politika kapsamında bilgilerinizin kullanılmasını istemiyorsanız, lütfen
        Hizmetler’i kullanmayın.
      </p>
      <p>
        <strong>
          Tarafınıza sunulan aydınlatma metninde sıralanan veri işleme süreçleri
          size uygunsa, ancak herhangi bir tanımlama ve doğrulama işlemine konu
          olmamasına rağmen, sesinizin kaydedilmesini istemiyorsanız, lütfen
          Uygulama üzerinde sesli sohbet özelliğini kullanmayın.
        </strong>
      </p>
      <h2>2. ÜYELİK VE TAAHHÜTLER</h2>
      <p>
        Kullanıcılar, Uygulama’yı indirip, kayıt için gerekli olan bölümleri
        doldurup, e-posta adresine gelen kod vasıtasıyla doğrulama işlemini
        gerçekleştirdikten sonra, işbu Sözleşme dâhilinde Uygulama’yı kullanmaya
        başlayacaktır. Kullanıcı, işbu Sözleşme’yi onaylayarak Uygulama ile
        sunulan tüm Hizmetler’den Sözleşme’deki hüküm ve şartlar çerçevesinde
        yararlanabilecektir. Sözleşme’yi akdetmeden önce tüm hüküm ve şartları
        DİKKATLİ BİR ŞEKİLDE OKUMANIZ gerekmektedir. Bununla birlikte, aşağıda,
        Aloha’nın öncelikli olarak dikkatinizi çekmek istediği bazı temel
        operasyonel şartların bir özeti bulunmaktadır.
      </p>
      <p>
        İşbu Sözleşme, genel olarak Dinleyici ve Konuşmacı’yı bir araya getiren
        bir Platform olarak Dinleyici ile Konuşmacı’nın etkileşime geçmesini
        sağlayan aracı bir çevrimiçi platformdur. Aloha, insanların sosyal
        olarak kendilerini yargılamayacak; tarafsız, deneyimli ve eğitimli
        dinleyicilerle gizlilik çerçevesinde hayatta karşılaştıkları zorlukları
        anlatabilecekleri bir mobil chat uygulamasıdır. Aloha’daki eğitimli
        dinleyiciler, kişileri yargılamadan onların sorunlarının farkında
        olmalarına ve çözümü bulmalarına yardımcı olmayı hedefler.
      </p>
      <p>
        Aloha’nın yükümlülüğü konuşmacılar ve dinleyiciler arasındaki iletişim
        için uygun çevrimiçi ortamın sağlanmasından ibarettir. Platform
        Konuşmacı ve Dinleyici üyeliği olarak iki ana hizmeti içermektedir.
        Kullanıcıların hem Konuşmacı hem de aşağıdaki şartları sağlayarak
        Dinleyici olması mümkündür.
      </p>
      <h3>2.1. Konuşmacı Hizmetine İlişkin Olarak</h3>
      <p>
        2.1.1. Aloha’da, konuşmacılar, kendi kriterlerine göre seçtikleri
        dinleyiciler ile birebir ve seçtikleri konuda sohbet edebileceklerdir.
      </p>
      <p>
        2.1.2. Konuşmacı, uygulamayı kullanırken; Dinleyici’nin özelliklerini
        Uygulama üzerinden sunulan seçenekleri kullanarak sisteme girebilir,
        favorilerine aldığı Dinleyici’yi sohbete davet edebilir yahut rastgele
        bir Dinleyici ile eşleşebilir.
      </p>
      <p>
        2.1.3. Konuşmacı’nın, kimliğini belirlemeye yarayan verileri sisteme
        kaydı zorunlu değildir. Konuşmacı, ayırt edici kişisel bilgilerini kendi
        isteğiyle Dinleyici’ye bildirirse, bu bilgiler, Aloha Kişisel Verilerin
        Korunması Politikası’na uygun şekilde işlenecek ve arşivlenecektir.
        Süreçlerde İşlenecek Verilerinize İlişkin Aydınlatma Metni ve Kişisel
        Verilerin Korunması Politikası’nı Uygulama üzerinde İletişim bölümünden
        ve web sayfamızda bulabilirsiniz. Lütfen hükümleri ayrıntılı şekilde
        inceleyiniz. Ek olarak, Profilinizde yer alan bilgileri her zaman
        Uygulama üzerindeki Ayarlar-Profil Ayarları bölümünden güncellemeniz
        mümkündür. Sözleşmeyi akdettiğiniz sırada uygulanan süre ve ücret
        düzenlemelerine{" "}
        <a
          className="text-dark fw-bold"
          href="https://indir.alohalive.online"
          rel="noreferrer"
        >
          indir.alohalive.online
        </a>{" "}
        linkinden ulaşabilirsiniz.
      </p>
      <p>
        2.1.4. Konuşmacı’ya ilettiği bilgilere göre bir konuşmacı kartı
        düzenlenir. Konuşmacı kartında, temel olarak; yaş, cinsiyet, duygu
        durumu, kullanıcı adı, sohbet dili, ilgilendiği konular ve sohbet süresi
        yer alır. Kullanıcı, tercihine göre cinsiyet butonunu boş bırakabilir.
      </p>
      <p>
        2.1.5. Konuşmacı, iletişim kurmak istediği dinleyici özelliklerini
        seçerek, favorilerinden bir dinleyiciyi davet ederek yahut
        rastgele/otomatik dinleyici seçimi yapabilir. Konuşmacı, kriterleri
        belirledikten sonra Dinleyici Arama Butonuna tıklar, sistemde uygun
        kriterde Dinleyici’ye aktarılır. Aloha, bu erişimin güvenli ve verimli
        şekilde sağlanması için gerekli platformu sağlayacak olup, Aloha’nın,
        Dinleyici’nin bu isteğe cevap vermesini sağlama sorumluluğu
        bulunmamaktadır.
      </p>
      <p>
        2.1.6. Konuşmacı, dilediği konuda Dinleyici ile iletişime geçebilir.
        Ancak, Konuşmacı iletişim sırasında Sözleşme’de ve yerel mevzuatta
        düzenlenen kuralları izleyeceğini, üçüncü kişilere saygılı olacağını,
        bunlara bağlı kalacağını kabul, beyan ve taahhüt eder. Bu kurallara
        aykırılık halinde tüm sorumluluk kurala aykırı davranan tarafa aittir.
        Konuşmacı, Dinleyici tarafından yahut başka bir şekilde bir aykırılığın
        gerçekleşmesi durumunda, Aloha’nın hiçbir şekilde sorumluluğunun
        bulunmadığını kabul, beyan ve taahhüt eder.
      </p>
      <p>
        2.1.7. Konuşmacı, Uygulama üzerinde seçtiği ya da eşleştiği Dinleyici
        ile, günlük 15 (onbeş) dakika ücretsiz konuşabilir. Konuşmacı, 15
        (onbeş) dakika sonrasında, sohbet sırasında açılan menüden, dinleyiciye,
        sohbeti uzatmak istediği süre değerinde ürünü ALC Coin olarak belirlenen
        bedel üzerinden hediye ederek konuşmaya devam edebilir. Süre ve hizmet
        bedeli hükümleri, Aloha tarafından tek taraflı olarak Platform’da
        ve/veya uygulama marketinde ilan edilerek güncellenebilir.
      </p>
      <p>
        2.1.8. Konuşmacı’nın, Uygulama üzerinde ürün hediye edebilmesi için,
        kullandığı cihazda bulunan uygulama marketi (AppStore, Google Play
        Store) üzerinden kredi satın alması gerekmektedir. Uygulama üzerindeki
        ALC Coin Al sekmesinden uygulama marketine erişilmesi gerekir. Satış
        işlemleri uygulama marketi üzerinden gerçekleştirildiğinden, Aloha
        hiçbir şekilde kredi kartı bilgilerinize erişmez ve saklamaz.
      </p>
      <p>
        2.1.9. Konuşmacı, kalan konuşma hakkı süresini, konuşmacı ekranına
        tıklayarak ve/veya cüzdan erkanından görüntüleyebilir.
      </p>
      <p>
        2.1.10. Konuşmacı, sohbet sırasında Dinleyici’ye ısmarladığı ürünlerin
        satın alınması ile ilgili Sözleşme tarafının uygulama marketi(AppStore,
        Google Play Store) olduğunu, iade süreçleri hakkında uygulama marketi
        ile akdettiği elektronik sözleme hükümlerinin uygulanacağını bildiğini,
        Aloha’dan sağladığı görüşme süresinin tamamını kullanmasa dahi kalan
        sürenin başka sohbetlere aktarılmasını talep edemeyeceğini kabul eder.
      </p>
      <p>
        2.1.11. Konuşmacı, yazışma ekranına aynı anda en fazla 3 (üç) dinleyici
        alabilir. Konuşmacı, dinleyicilerin kriterlerini ayrı ayrı seçebilir
        yahut otomatik aktarım talep edebilir. Her bir dinleyici için dakika
        modülü ayrı ayrı hesaplanacaktır.
      </p>
      <p>
        2.1.12. Konuşmacı, Dinleyici’ye puan verebilir ve/veya favorilerine
        ekleyebilir. Favorilerine eklemesi halinde menüde bulunan
        “favorilerimden seç” sekmesini kullanarak aynı dinleyiciye tekrar istek
        gönderebilir. Dinleyici’nin müsaitlik durumuna göre iletişim sağlanır.
        Aloha, Dinleyici’nin müsaitlik durumunu garanti etmez.
      </p>
      <p>
        2.1.13. Konuşmacı, Dinleyici’nin Uygulama’da hazır bulunduğu sırada
        sohbete devam etmelidir. Aksi halde, kullanım bedeli, satın aldığı paket
        üzerinden işlemeye devam eder. Konuşmacı, o sırada uygulamayı
        kullanmasına engel bir durumun ortaya çıktığını ileri sürerek herhangi
        bir hak iddia edemez. Konuşmacı’nın Uygulama’dan kaynaklanan bir nedenle
        sistemi kullanamaz duruma gelmesi hali bu kuralın istisnasıdır. Aloha,
        kendi belirlediği süre zarfında sohbette 3 (üç) dakika boyunca herhangi
        bir işlem yapılmazsa sohbeti sonlandırma hakkına sahiptir.
      </p>
      <p>
        2.1.14. Yaş Sınırı: Reşit olmayanlar Aloha Kullanıcısı olamaz. Veli/vasi
        onayı ile ALOHA Kullanıcılığı mümkün değildir.
      </p>
      <p>
        2.1.15. Konuşmacı, İşbu Sözleşme’yi imzalamasıyla birlikte 18 yaşından
        büyük olduğunu peşinen kabul ve taahhüt eder. Konuşmacı’nın reşit
        olmaması durumunda (18 yaşından küçük), ağır ruhsal bozukluklarda,
        psikoterapi gerektiren durumlarda, ilaç tedavisini gerektiren
        psikiyatrik sorunlarda, kişinin intihar riski taşıması durumlarında ve
        mevzuat açısından suç unsuru içeren durumlarda bu hizmeti kullanmaması
        gerekmektedir. Konuşmacı, yukarıda sayılan durumların içinde olmadığını
        beyan eder. Konuşmacı’nın bu gibi önemli niteliklerinde Dinleyici’yi
        yanılttığı durumlarda doğabilecek herhangi bir sorundan Dinleyici ve/ve
        Aloha sorumlu değildir. Böyle bir durum fark edildiğinde Aloha, erişimi
        derhal kısıtlama hakkına sahiptir. Dinleyici, bu durumu derhal şikâyet
        edebileceği gibi, konuşmaya gözlemci olarak admin de çağırabilir.
        Konuşmacı bu durumu bildiğini kabul ve beyan eder.
      </p>
      <p>
        2.1.16. Konuşmacı, Dinleyici’nin, Aloha’nın temsilcisi olmadığını ve
        Dinleyici’nin eylemlerinden Aloha’nın doğrudan yahut dolaylı olarak
        sorumlu tutulamayacağını bildiğini kabul, beyan ve taahhüt eder. Aloha,
        Konuşmacı’ya Dinleyici ile görüşmesinin verimliliğine ilişkin hiçbir
        taahhütte bulunmaz.
      </p>
      <p>
        2.1.17. Konuşmacı, kendisine tahsis edilen Kullanıcı adını ve şifresini
        3.şahıslara kullandıramaz. Aksinin tespiti halinde Aloha tarafından
        erişim derhal engellenebilir. Üçüncü kişiye kullandırma nedeniyle
        meydana gelebilecek her türlü zarardan üçüncü kişi ile birlikte
        Kullanıcı müteselsilen sorumlu olacağını bildiğini kabul, beyan ve
        taahhüt eder. Konuşmacı, hesabının izinsiz kullanıldığını tespit ederse
        derhal Aloha’ya aynı gün içerisinde bildirimde bulunmalıdır. Makul
        sürede bildirimde bulunmaması nedeniyle oluşan zarardan Kullanıcı
        sorumlu olacaktır.
      </p>
      <p>
        2.1.18. Aloha, Dinleyici’nin niteliklerini, bilgi birikimini, geçmişini
        doğrulamakla mükellef değildir. Aloha’nın bu konuda özen gösteriyor
        olması hiçbir şekilde sorumlu olduğunu kabul şeklinde yorumlanamaz.
      </p>
      <p>
        2.1.19. Uygulamada sergilenen içerik; gerekli görülmesi halinde Aloha
        tarafından incelenebilir. İçeriğin sözleşme kurallarına yahut yasalara
        aykırı olduğunun tespiti halinde içerik engellenir ve içeriği
        sergileyenin hesabı süresiz olarak kapatılır.
      </p>
      <p>
        2.1.20. Konuşmacı, Dinleyici’nin kendisine veya başkalarına zarar
        vereceği yönünde kuvvetli veriler elde ederse, bu durumda durumu derhal
        Aloha’ya ve kolluk kuvvetine haber vermekle ve konunun aydınlatılması
        için gerekli yardımı sağlamakla mükelleftir. Kullanıcı Uygulama üzerinde
        şikâyet et butonuna tıklayarak Aloha’yı hızlıca bilgilendirmelidir.
      </p>
      <p>
        2.1.21. İletişimin gizliliği esas olmakla birlikte acil durum bildirimi
        (taciz, hakaret içerikli yazışma, uygunsuz sohbet içeriği, uzun süredir
        cevap alamama bildirimi, diğer) halinde, Dinleyici’nin şikâyeti üzerine
        admin sohbete davet edilebilir. Admin, sohbeti ilk adımda anonim olarak
        kontrol edecek, Sözleşme’ye yahut mevzuata{" "}
      </p>
      <h3>2.2.Dinleyici Hizmetine İlişkin Olarak</h3>
      <p>
        2.2.1. Dinleyici adayı; ad, soyad, kullanıcı adı, doğum tarihi,
        elektronik posta adresi ve şifre bilgilerini doldurarak ve sohbet
        konusunu seçerek sisteme kaydolur.
      </p>
      <p>
        2.2.2. Dinleyici, Aloha tarafından verilen eğitim ve onay sonrası
        dinleyici sıfatını kazanır ve dinleyici kartı oluşturulur. Dinleyici
        kartında, dinleyicinin; yaş, cinsiyet, duygu durumu, sohbet dili,
        ilgilendiği konular, uygulamaya kaç aydır üye olduğu, kullanıcı adı, kaç
        konuşmacı tarafından favorilere eklendiği, aktif edilmesi halinde puan
        bilgisi yer alır.
      </p>
      <p>
        2.2.3. Dinleyici, şifresini başka kişilerle paylaşamaz. Paylaşması
        durumunda ortaya çıkacak tüm zarar ve ziyan münhasıran Dinleyici’nin
        sorumluluğundadır. Dinleyici, böyle bir durumda Aloha’yı tüm tazminat
        hak/alacak taleplerinden ari tutacaktır.
      </p>
      <p>
        2.2.4. Dinleyici, sistemde kendisine gelen çağrılardan birini kabul
        ettikten sonra sohbete başlayabilir.
      </p>
      <p>
        2.2.5. Dinleyici, Konuşmacı’yı kişisel bilgilerini vermeye zorlayamaz ve
        teşvik edemez. Konuşmacı’nın konuşma esnasında, iradi olarak kişisel
        bilgilerini aktarması durumunda, Dinleyici, Aloha Kişisel Verilerin
        Korunması Politikası’na uygun hareket edeceğini, aksi durumda oluşan her
        türlü zarardan münhasıran sorumlu olduğunu ve Aloha’yı tüm bu zarardan
        ari tutacağını kabul, beyan ve taahhüt eder.
      </p>
      <p>
        2.2.6. Konuşmacı’nın iradesiyle ayırt edici kişisel bilgilerini
        Dinlenici’ye aktarması durumunda Dinleyici bu bilgileri Aloha Kişisel
        Verilerin Korunması Politikası çerçevesinde tutmakla mükelleftir.
      </p>
      <p>
        2.2.7. Dinleyiciye Erişim: Hizmetin gereği gibi ifası için,
        Konuşmacı’nın, iletişim kurmak istediği Dinleyici’ye ulaşabilmesi kolay
        olmalıdır. Bu nedenle Dinleyici, ulaşılabilir olmak ve görüşme sırasında
        interaktif olmak için azami özeni gösterir. Aloha, bu erişimin
        sağlanması için gerekli platformu sağlayacak olup Dinleyici’nin
        zamanında bu isteğe cevap vermesini yahut interaktif bir iletişim
        sürdürmesini sağlama yükümlülüğü bulunmamaktadır.
      </p>
      <p>
        2.2.8. İnsan faktörü göz önünde bulundurulduğunda, Konuşmacı, genel
        ahlak, kamu düzeni ve yürürlükteki mevzuata aykırılık taşıyan konularda
        Dinleyici ile iletişime geçmeye teşebbüs edebilecektir. Dinleyici, bu
        durumu bildiğini, bu şartlarda işbu sözleşmeyi imzaladığını kabul, beyan
        ve taahhüt eder. Ancak Konuşmacı’nın iletişim sırasında sözleşmede ve
        mevzuatta düzenlenen kurallara aykırı davranışının tespiti durumunda
        Dinleyici bu durumu derhal Aloha’ya bildirir. Dinleyici, Konuşmacı
        tarafından yahut başka bir şekilde bir aykırılığın gerçekleşmesi
        durumunda, Aloha’nın hiçbir şekilde sorumluluğunun bulunmadığını kabul
        eder.
      </p>
      <p>
        2.2.9. Dinleyici, Uygulama’yı kullanırken kullandığı ifadelerden tek
        başına sorumludur. Bu itibarla, Dinleyici, bu sözleşmede ve mevzuatta
        düzenlenen kurallara riayet edeceğini ve bunlara bağlı kalacağını kabul
        ve taahhüt eder.
      </p>
      <p>
        2.2.10. İletişimin gizliliği esas olmakla birlikte şikâyet halinde yahut
        gerekli görülen hallerde, admin sohbete davet edilebilir. Admin, sohbeti
        ilk adımda anonim olarak kontrol edecek, Sözleşme’ye yahut mevzuata
        aykırı bir durum varsa, ilgili bilgileri Şirket yetkililerine kişisel
        verileri de içerecek şekilde aktaracaktır. Admin, bu ziyareti görünmeden
        yapabilir veya Dinleyici ile aynı bir ekranda iletişim kurabilir.
      </p>
      <p>
        2.2.11. Dinleyiciler konuşmacılar tarafından derece sistemine tabi
        tutulur. Aloha, tek taraflı kararı ile kötü dereceye sahip ve/veya
        şikâyet edilen dinleyicileri sistemden çıkarabilir. Dinleyici, bu karar
        nedeniyle, herhangi bir hak, alacak, tazminat talebinde bulunamaz.
      </p>
      <p>
        2.2.12. Dinleyici, iletişimin başladığı andan itibaren 15 (onbeş) dakika
        boyunca Konuşmacı ile herhangi bir bedel talep etmeksizin sohbet
        edecektir. Konuşmacı, 15 (onbeş) dakika sonrasında, sohbet sırasında
        açılan menüden, dinleyiciye, sohbeti uzatmak istediği süre kadar
        belirlenen ALC Coin bedelinde ürünü hediye ederek konuşmaya devam
        edebilir.
      </p>
      <p>
        2.2.13. Konuşmacı’nın, Uygulama üzerinde ürün hediye edebilmesi için,
        kullandığı cihazda bulunan uygulama marketinden (AppStore, Google Play
        Store) üzerinden ALC Coin satın alması gerekmektedir. Uygulama
        üzerindeki Satın Al sekmesinden uygulama marketine erişilmesi
        gerekmektedir.
      </p>
      <p>
        2.2.14. Dinleyici, Konuşmacı’nın iletişimin devamı için satın aldığı ALC
        Coin tutarlarının Aloha tarafından belirlenecek olan ve Aloha’nın
        takdirine bağlı olarak Platform’da yayınlanarak değiştirilebilecek bir
        oranında, ALC Coin’i Dinleyici’ye kazanç olarak, “Kazanç Geçmişi”
        sekmesine kaydedecektir. Dinleyici, bu kazanç tutarlarını; Kullanıcı
        hesabına aktarabileceği ve kullanıcı olarak harcayabileceği gibi,
        Uygulama üzerinden, “AlohaLive Kuponlar” sekmesinden, Aloha’nın
        anlaşmalı olduğu e-ticaret platformlarında, Aloha ve e-ticaret sitesi
        tarafından belirlenen bedel karşılığında kupon olarak da kullanabilir.
        Dinleyici, Aloha’nın ödeme sistemleri kullanmaması, ALC Coin’lerin
        uygulama marketi üzerinden satın alınması nedeniyle, bu tutarların
        herhangi bir şekilde paraya çevrilemeyeceğini, bu şartlarla Hizmet’i
        kullanmaya devam ettiğini ve bu şartları kabul ettiğini kabul, beyan ve
        taahhüt eder. Güncel kupon linklerine ve ALC Coin kazanç oranlarına{" "}
        <a
          className="text-dark fw-bold"
          target="_blank"
          href="Https://indir.alohalive.online"
          rel="noreferrer"
        >
          indir.alohalive.online
        </a>{" "}
        adresinden erişebilirsiniz.
      </p>
      <p>
        2.2.15. Dinleyici, Konuşmacı’dan Aloha sistemi dışında herhangi bir
        hediye veya şahsi menfaat temini talebinde bulunamaz, yürürlükteki
        mevzuat, kamu düzeni ve genel ahlaka aykırı herhangi bir talepte
        bulunamaz.
      </p>
      <p>
        2.2.16. Konuşmacı tarafından başlatılan sohbette Dinleyici’nin tek bir
        Kullanıcı ile ilgilenmesi ve Kullanıcı ile hızlı ve etkin iletişim
        içinde olması esastır.
      </p>
      <p>
        2.2.17. Dinleyici’nin, Konuşmacı’nın Aloha sisteminde hazır bulunduğu ve
        erişimin sağlandığı sırada aktif olması, zamanında yanıt vermesi
        gerekmektedir. Dinleyici, 3 (üç) dakikayı aşan süreyle Konuşmacı’ya
        yanıt vermezse yahut Dinleyici, Konuşmacı’ya ayrılan sürede etkili
        iletişimi sağlayamazsa sistem kapanır ve işbu Sözleşme’de ve duyurularda
        kararlaştırılan ALC Coin’e hak kazanamaz.
      </p>
      <p>
        2.2.18. Aloha, konuşma sonrasında, Konuşmacı’dan, Dinleyici için
        performans değerlendirmesi yapmasını isteyebilir. Aloha tarafından
        belirlenen performansın altında kalan dinleyiciler sistem dışına
        çıkarılır ve hesabında biriken ALC Coin miktarları Aloha tarafından
        cezai şart olarak irat kaydedilir.
      </p>
      <p>
        2.2.19. <strong>Yaş Sınırı:</strong> Reşit olmayanlar Aloha dinleyicisi
        olamaz. Dinleyici, İşbu Sözleşme’yi imzalamasıyla birlikte 18 yaşından
        büyük olduğunu peşinen kabul ve taahhüt eder.
      </p>
      <p>
        2.2.20. <strong>Eğitim:</strong> : Aloha, dinleyici adayını eğitime tabi
        tutar. Bu halde, eğitimi almayan ve Aloha’dan dinleyicilik onayı almayan
        kişiler Dinleyici olamaz. Dinleyici, verilen eğitimin içeriğini gizli
        tutar ve üçüncü kişilerle paylaşmaz. Paylaşması halinde doğacak her
        türlü zarardan münhasıran sorumlu olacağını kabul, beyan ve taahhüt
        eder.
        <br />
        Dinleyici’nin eğitimi tamamlamak için toplamda 3(üç) hakkı vardır.
        Aloha, bu sistemi tek taraflı olarak değiştirebilir. Dinleyici, eğitim
        sırasında eğitim ekranında kalmalıdır, eğitimi tamamlamadan sistemden
        çıkarsa, bu süreç eğitim hakkı sayısından düşecektir. Eğitim sırasında
        dinleyicinin; doğru sayısı, yanlış sayısı, başarı yüzdesi, kalan eğitim
        hakkı sayısı bilgileri Platform’da işlenir. Başarısız olan Dinleyici’nin
        eğitim hakkı kazanmak için, iletişime geç butonuna tıklaması ve tekrar
        ilgili eğitimi tamamlaması gerekmektedir.
      </p>
      <p>
        2.2.21.Dinleyicinin Uygulamayı Kötüye Kullanamaması: Dinleyici, Aloha
        uygulamasını yalnızca sözleşmenin ve ilgili mevzuatın izin verdiği
        şekilde kullanabilir. Bu kurala aykırı hareketi sonucu doğacak tüm
        zarardan tek başına sorumlu olur ve Dinleyici erişimi derhal kapatılır.
        Kötüye kullanım nedeniyle sözleşmenin feshi durumunda geriye etkili
        olarak hak kazanılan ALC Coin miktarları sıfırlanır ve Dinleyici’ye
        hiçbir ödeme yapılmaz.
      </p>
      <p>
        2.2.22. Dinleyicinin Kişisel Verileri Gizli Tutması Gerekliliği:
        Dinleyici’nin Uygulama üzerinden eriştiği görüntü, yazışma ve benzeri
        her türlü verileri anonim veri de olsa herhangi bir sosyal medya
        platformunda yahut benzeri iletişim platformları vasıtasıyla paylaşması
        yasaktır. Bu kurala aykırı hareketi sonucu doğacak tüm zarardan tek
        başına sorumlu olur ve Dinleyici erişimi derhal kapatılır. Bu hal de
        kötüye kullanım hallerindendir ve kötüye kullanım nedeniyle sözleşmenin
        feshi durumunda geriye etkili olarak hak kazanılan ALC Coin miktarları
        sıfırlanır ve Dinleyici’ye hiçbir ödeme yapılmaz.
      </p>
      <p>
        2.2.23. Dinleyici, kendisine tahsis edilen hesabı, 3.şahıslara
        kullandıramaz. Aksinin tespiti halinde erişimi derhal kısıtlanır ve o
        tarihe kadar hak kazandığı komisyon kredisi (ALC Coin) bakiyesi cezai
        şart olarak irat kaydedilir. Yine, bu durumda meydana gelebilecek her
        türlü zarardan 3.kişi ile müteselsilen sorumlu olur. Dinleyici,
        hesabının izinsiz kullanıldığını tespit ederse derhal e-posta yoluyla
        yahut Uygulama üzerinden Aloha’ya bildirecektir. Bildirimin aynı gün
        yapılması kuraldır. Aksi halde doğacak her türlü zarardan sorumlu
        olacaktır.
      </p>
      <p>
        2.2.24. Dinleyici, Sözleşme’nin kurulduğu sırada verdiği kişisel
        bilgilerin gerçeğe uygun olduğunu taahhüt eder. Dinleyici’nin gerçeğe
        aykırı bildirimde bulunması nedeniyle doğacak her türlü doğrudan ve
        dolaylı zarar münhasıran Dinleyici’nin sorumluluğundadır.
      </p>
      <p>
        2.2.25. Dinleyici, Uygulama’yı, suç sayılabilecek şekilde, gayri kanuni
        işlerde, kullanamaz. İşbu hükme aykırılığının tespiti halinde Dinleyici
        hesabı süresiz olarak kapatılır. Bu durumda Dinleyici bir daha Uygulama
        üzerinde Aloha kullanıcısı yahut dinleyicisi olamaz ve hesabında biriken
        ALC Coin miktarları Aloha tarafından cezai şart olarak irat kaydedilir.
      </p>
      <p>
        2.2.26. Uygulamada sergilenen içerik; gerekli görülmesi halinde Aloha
        tarafından incelenebilir. İçeriğin sözleşme kurallarına yahut yasalara
        aykırı olduğunun tespiti halinde içerik engellenir ve içeriği
        sergileyenin hesabı süresiz olarak kapatılır. Bu eylemin Dinleyici
        kaynaklı olması halinde hesabında biriken ALC Coin miktarları Aloha
        tarafından cezai şart olarak irat kaydedilir.
      </p>
      <p>
        2.2.27. Dinleyici, Uygulama’dan kaynaklanan bir nedenle sistemi
        kullanamaz duruma gelirse, durumu derhal Aloha’ya bildirecektir. Makul
        sürede bildirilmemesi nedeniyle oluşan zararlardan Aloha sorumlu
        değildir.
      </p>
      <p>
        2.2.28. . Dinleyici, Kullanıcı’nın kendisine veya başkalarına zarar
        vereceği yönünde yahut kullanıcının Uygulama’yı suç teşkil eden şekilde
        kullandığı yönünde kuvvetli veriler elde ederse, bu durumda durumu
        derhal Aloha’ya ve kolluk kuvvetine haber vermekle ve konunun
        aydınlatılması için gerekli yardımı sağlamakla mükelleftir.
      </p>
      <h2>
        3. ALOHA TARAFINDAN SÖZLEŞME KAPSAMINDA SUNULAN HİZMETLER'E İLİŞKİN
        KULLANICI TAAHHÜT VE YÜKÜMLÜLÜKLERİ
      </h2>
      <h3>3.1.1. Hesap Kaydı ve Güvenliği</h3>
      <p>
        Kullanıcı ve Dinleyici, Hizmetler’e erişmek için bir hesap oluşturması
        gerekeceğini bilmektedir. Bu anlamda; Dinleyici (a) gerçek, doğru,
        güncel ve tam Kayıt Verisi’ni sağlamanız ve (b) Kayıt Verileri’ni doğru,
        güncel ve eksiksiz tutmak için muhafaza etmeniz ve derhal güncellemeniz
        gerekmektedir. Gerçek olmayan, yanlış, güncel olmayan veya eksik
        herhangi bir bilgi sağlamanız yahut Aloha’nın işbu bilgilerin doğru veya
        güncel olmadığından ya da yanlış veya eksik olduğundan şüphelenmek için
        makul gerekçelerinin bulunması durumunda Aloha, Hesabınızı askıya alma
        veya feshetme ve Hizmetler’in (veya herhangi bir bölümünün) mevcut veya
        gelecekteki tüm kullanımını engelleme hakkına sahip olacaktır. Hem
        Kullanıcı hem de Dinleyiciler için, Şifrenizin ve hesabınızın
        güvenliğinin ve gizliliğinin sağlanması sorumluluğu Size aittir. Buna ek
        olarak, hesabınız kapsamında gerçekleştirilen tüm aktivitelerden ötürü
        sorumluluk tarafınıza aittir. Hesap bilgilerinizi veya Kullanıcı adınızı
        ve şifrenizi herhangi bir üçüncü kişi ile paylaşmamanız yahut herhangi
        bir üçüncü kişinin hesap bilgilerinizi kullanarak Hizmetler’de oturum
        açmasına izin vermemeniz gerekmektedir. Hesabınızda herhangi bir
        yetkisiz kullanımı veya farkına vardığınız diğer herhangi bir güvenlik
        ihlalini derhal tarafımıza bildirmeyi kabul etmektesiniz. Durumunuza ve
        Hizmetler’in kullanım amacına en uygun önlemlerin alınması ve güvenlik
        önlemlerinin temin edilmesi hususundaki sorumluluk tarafınıza aittir.
        Belirli koşullar altında (işbu Sözleşme’nin ifa edilmesi ve yönetilmesi,
        haklarımızın kullanılması, yasal sorumluluğumuz, hukuki süreçler,
        emirler, celpler veya müzekkerelere yanıt vermek üzere; haklarımızın,
        müşterilerimizin, işletmemizin korunması adına veya bazı durumlarda
        hükümetler veya yetkililerle zorunlu veri paylaşımının bir sonucu olarak
        gerekli olduğu ölçüde) , Kullanıcı bilgilerini, hesap, İçerik veya
        kullanım kayıtlarını ve ilgili bilgileri üçüncü taraflar ile paylaşma
        hakkımız bulunmaktadır.
      </p>
      <h4>3.1.2. Erişim lisansı</h4>
      <p>
        Kullanıcı tarafından işbu Sözleşme’ye sıkı bir biçimde uyulması şartına
        bağlı olarak Aloha Kullanıcı’ya (Dinleyici&Konuşmacı), Hizmetler’e veya
        içeriğine erişilmesi ve bunların kullanılması hususunda sınırlı, geri
        alınabilir, münhasır olmayan, devredilemez bir lisans vermektedir. Bu
        lisans, Hizmetler’in veya Ürünler’in yeniden satışını veya ticari olarak
        kullanılmasını; herhangi bir ürün listelemesinin, açıklamasının veya
        fiyatının toplanmasını ve kullanılmasını; Hizmetler’in veya Ürünler’in
        ikincil kullanımını; hesap bilgilerinin başka bir tacirin yararına veya
        Kullanıcı’nın ticari yararına herhangi bir şekilde indirilmesini veya
        kopyalanmasını veya veri madenciliği, robotlar, tanımlama bilgileri,
        kazıma veya benzeri veri toplama ve çıkarma araçlarının herhangi bir
        kullanımını içermemektedir. Burada açıkça izin verilen hâller haricinde
        Hizmetler ve/veya Hizmetler’in herhangi bir bölümü, 5846 sayılı Fikir ve
        Sanat Eserleri Kanununda belirtilen koşullar altında, Aloha’nın açık
        yazılı izni olmaksızın herhangi bir amaçla çoğaltılamaz, satılamaz,
        yeniden satılamaz, ziyaret edilemez veya başka bir şekilde kullanılamaz.
        Herhangi bir yetkisiz kullanım, tarafımızca Size verilmiş olan izinleri
        ve/veya lisansları kendiliğinden sona erdirecektir.
      </p>
      <h4>3.1.3. Telif Hakkı ve Mülkiyet</h4>
      <p>
        Uygulamada yer alan içeriğin tamamı Aloha’ya, lisans verenlerine,
        temsilcilerine, hizmet sağlayanlarına ve/veya İçerik sağlayıcılarına
        aittir. Genel tasarım ve içerik dahil ancak bunlarla sınırlı olmaksızın
        Hizmetler’in tüm unsurları, ticari takdim şekli, telif hakkı, manevi
        haklar, ticari marka ve fikri mülkiyet haklarıyla ilgili diğer yasalar
        uyarınca korunmaktadır. Hizmetler, yalnızca bu Hizmetler’in kullanıma
        sunulması ile amaçlanan amaç için kullanılabilir. Telif yasaları ile
        izin verilen hâller hariç olmak üzere materyallerden herhangi birini
        değiştirmeniz, Hizmetler’de yer alan herhangi bir bilgiyi veya çalışmayı
        kopyalayabilmeniz, dağıtabilmeniz, iletebilmeniz, görüntüleyebilmeniz,
        icra edebilmeniz, çoğaltabilmeniz, yayınlayabilmeniz,
        lisanslayabilmeniz, bunlardan ikincil çalışmalar oluşturabilmeniz,
        aktarabilmeniz veya satabilmeniz yasaktır. Telif yasaları kapsamında
        izin verilen hâller dışında, Hizmetler’de yer alan ve telif hakkıyla
        korunan herhangi bir materyalin yeniden kullanımı öncesinde izin
        alınması Sizin sorumluğunuzdadır. Hizmetler’in tarafınızca kullanımı ile
        ilgili olarak, yürürlükte olan tüm ulusal ve uluslararası yasalara,
        tüzüklere, nizamnamelere ve yönetmeliklere uymakla yükümlüsünüz. Aksi
        açıkça kararlaştırılmadığı müddetçe Hizmetler, İçeriği ve bunlarla
        ilgili tüm hakların mülkiyeti, münhasıran Aloha’ya veya lisans
        verenlerine, hizmet sağlayanlarına, temsilcilerine ve/veya İçerik
        sağlayıcılarına aittir. Hizmetler’de yer alan materyaller üzerindeki
        herhangi bir telif hakkı, ticari marka veya diğer mülkiyet ile ilgili
        notu kaldırmanız yasaktır.
      </p>
      <h4>3.1.4. Ticari Markalar/Onay Olmaması</h4>
      <p>
        Tüm Markalar, Aloha’nın veya bağlı ortaklıklarının, ortaklarının, hizmet
        sağlayıcılarının veya lisans verenlerinin ticari markaları veya tescilli
        ticari markalarıdır. Aloha’nın önceden yazılı izni olmaksızın,
        Hizmetler’deki materyallerin dağıtımına ilişkin reklam veya tanıtım
        dahil olmak üzere, Aloha ticari markalarını hiçbir şekilde kullanamaz,
        kopyalayamaz, çoğaltamaz, yeniden yayınlayamaz, yükleyemez, gönderemez,
        iletemez, dağıtamaz veya değiştiremezsiniz. Aloha’nın önceden yazılı
        izni olmaksızın, (i) herhangi bir yazılı veya sözlü reklam veya sunumda
        yahut (ii) broşür, haber bülteni, kitap veya herhangi bir nitelikte
        diğer yazılı materyallerde, Aloha’nın takdiri uyarınca Aloha’nın onayını
        ima edebilecek bir şekilde, Aloha’nın adını veya herhangi bir ifadeyi,
        resmi veya sembolü kullanmanız mümkün değildir.
      </p>
      <h4>3.1.5. Hizmetler’in Kullanılabilirliğine İlişkin Kısıtlamalar</h4>
      <p>
        Hizmetler’i yılda 365 gün sağlamak adına her türlü çabayı göstermekle
        birlikte, öngörülemeyen olaylar yahut diğer koşullar Aloha tarafından
        Hizmetler’in sunulmasını engelleyebileceğinden Aloha, Hizmetler’in her
        daim kullanılabilir olacağını garanti etmemektedir.
      </p>
      <h4>3.1.6. Uygunsuz Materyal</h4>
      <p>
        Kanuna aykırı, ihlal teşkil eden, tehdit içeren, küçük düşürücü, iftira
        niteliğinde, müstehcen, pornografik veya saygısız materyallerin, küfür
        içeren materyallerin veya dinleri aşağılayıcı ya da hor gören
        materyallerin yahut genel ahlaka aykırı diğer herhangi bir materyalin
        veya üçüncü şahıs fikri mülkiyetini ihlal eden yahut kötüye kullanan
        veya suç teşkil eden ya da herhangi bir kanunu ihlal eden bir davranış
        teşkil edebilecek yahut bunları teşvik edebilecek herhangi bir
        materyalin iletilmesi amacıyla kullanıcı ve dinleyicilerin Uygulama’yı
        kullanması yasaktır. Kanunlar veya dürüstlük kuralı uyarınca sahip
        olabileceğimiz her türlü hukuki çareye yoluna ek olarak, tamamen ve
        münhasıran takdirimize bağlı olarak, yukarıda belirtilen yasakların
        yahut işbu Sözleşme ile bağlantısı bulunan uygulanacak herhangi bir
        kuralı ihlal ettiğinizin ya da ihlal etme olasılığınızın bulunduğunun
        tarafımızca belirlenmesi durumunda, önceden bildirimde bulunmaksızın,
        Hizmetler’in tarafınızca kullanımının yasaklanması dahil ancak bununla
        sınırlı olmaksızın, ihlalin ortadan kaldırılması veya önlenmesi
        hususunda gerekli gördüğümüz her türlü eylemi gerçekleştirme hakkımızın
        bulunduğunu belirtiriz. Bu tür materyalleri ileten herhangi birinin
        kimliğinin ifşa edilmesini talep eden veya bize talimat veren herhangi
        bir kanun uygulayıcı makam yahut mahkeme emri ya da mahkeme celbi
        almamız durumunda, tam bir iş birliği içinde hareket edeceğiz.
      </p>
      <h4>3.1.7. Erişim ve Müdahale</h4>
      <p>
        Konuşmacı ve Dinleyici, açık yazılı iznimiz olmaksızın Hizmetler’e
        erişmek için herhangi bir robot, ağ, kazıyıcı veya diğer otomatik
        Araçlar kullanmayacağını kabul etmektedir. Buna ek olarak, (i) tamamen
        ve mutlak takdirimize bağlı olarak, altyapımıza makul olmayan veya
        orantısız biçimde büyük bir yük getiren veya getirebilecek herhangi bir
        eylemde bulunmayacağınızı; (ii) herhangi bir başka Kullanıcının ve/veya
        Dinleyicinin hesabına müdahale edilmesi dahil olmak üzere, sitenin
        ve/veya Uygulamanın düzgün çalışmasına veya Hizmetler üzerinde
        gerçekleştirilen herhangi bir faaliyete müdahale etmeyeceğinizi veya
        müdahale girişiminde bulunmayacağınızı ya da (iii) Hizmetler’e erişimi
        engellemek veya kısıtlamak için kullanabileceğimiz hiçbir önlemi devre
        dışı bırakmayacağınızı kabul etmektesiniz.
      </p>
      <h4>3.1.8. İçeriği Kaldırma Hakkı</h4>
      <p>
        Hizmetler’in ve sistemlerinin olağan bakımı ve hostingi sırasında
        gerekmediği sürece veya kanunen zorunlu olmadıkça ya da bu tür bir
        eylemin (1) yasalara uyulması, Aloha’ya veya Hizmetler’e dair yasal
        süreçlere uyulması; (2) Aloha’nın, Hizmetler’in veya Hizmet
        Kullanıcılarının haklarının veya mülkiyetinin korunması ve savunulması
        veya (3) Kullanıcılarımızın, Hizmetler’in veya kamunun güvenliğinin
        korunması adına acil bir durumda harekete geçilmesi için gerekli
        olduğuna iyiniyetle inanmadığımız müddetçe, Kişisel Verilerin Korunması
        Politikasında açıklananlar haricinde, e-posta İçerikler’iniz veya
        Hizmetler’e gönderilen İçerik tarafımızca izlenmeyecek, düzenlenmeyecek
        yahut ifşa edilmeyecektir. Kullanıcılar ve dinleyiciler, mesajlarının
        içeriğinden münhasıran sorumlu olmaya devam edecek olup Aloha’nın bu tür
        herhangi bir içeriği önceden tarama yükümlülüğü bulunmamaktadır. Bununla
        birlikte, tamamen kendi takdirimize bağlı olarak, Hizmetler’e gönderilen
        veya Hizmetler’de yayınlanan herhangi bir materyali herhangi bir
        zamanda, bildirimde bulunmaksızın düzenleme, yayınlamayı reddetme veya
        kaldırma hakkımız bulunmaktadır. Yukarıdakilere halel gelmeksizin, işbu
        Sözleşme hükümlerini ihlal ettiğini veya başka bir şekilde sakıncalı
        veya yasaya aykırı olduğunu değerlendirdiğimiz herhangi bir materyali
        kaldırma ve buna ek olarak, Sözleşme’nin herhangi bir hükmüne uymayan
        herhangi bir Kullanıcı’nın Hizmetler’e yahut Hizmetler’in herhangi bir
        bölümüne erişimini reddetme hakkımız bulunmaktadır. Sözleşme’de eylemler
        özelinde düzenlenen yaptırım hükümleri saklıdır.
      </p>
      <h4>3.1.9. Kullanıcı ve Dinleyici Gönderileri</h4>
      <p>
        Gönderileri’nizin fikri mülkiyet hakkı tarafınıza ait olup bununla
        birlikte, işbu Sözleşme ile Aloha’ya açıkça, 5846 sayılı Fikir ve Sanat
        Eserleri Kanununda belirtilen şartlara tabi olarak, bu içeriğin
        tamamının veya bir kısmının dünyanın herhangi bir yerinde ve herhangi
        bir biçimde kullanılması, çoğaltılması, değiştirilmesi, uyarlanması,
        yayınlanması, tercüme edilmesi, ikincil çalışmalar oluşturulması,
        dağıtılması, iletilmesi, icra edilmesi ve görüntülenmesi için münhasır
        olmayan, daimi, geri alınamaz, telifsiz, tamamen ödenmiş, dünya çapında,
        tamamen alt lisanslanabilir bir hak ve lisans vermektesiniz.
      </p>
      <h4>3.1.10. Uygun Olmayan Kullanıcı ve Dinleyici Gönderileri</h4>
      <p>
        Gönderilerin yalnızca Aloha’nın zorunlu tuttuğu şekilde sağlanması ve
        Kullanıcı dışında herhangi bir kişiyi tasvir eden veya tanımlayan ya da
        işbu Sözleşme ile kararlaştırılan yükümlülüklere aykırı olan
        İçerikler’in tarafımıza sağlanmaması gerekmektedir.
      </p>
      <h4>3.1.11. Üçüncü Taraf Linkleri</h4>
      <p>
        Zaman zaman Hizmetler, Aloha veya bağlı ortaklıklarının mülkiyetinde
        olmayan, bunlar tarafından işletilmeyen veya kontrol edilmeyen web
        sitelerine bağlantılar içerebilmektedir. Bu tür bağlantıların tamamı,
        yalnızca Size kolaylık sağlamak amacıyla ve reklam faaliyetleri
        kapsamında sağlanmaktadır. Bu linkleri kullanmanız hâlinde Hizmetler’den
        ayrılırsınız. Bizim veya ilgili bağlı ortaklıklarımızdan herhangi
        birinin, başka herhangi bir web sitesinde bulunan yahut bu web
        sitesinden erişilebilen herhangi bir İçerik, materyal veya diğer
        bilgilerden ya da Size ait verilerin bu tür herhangi bir web sitesi
        tarafından kullanılmasından ötürü sorumluluğumuz bulunmamaktadır. Diğer
        web siteleri, bu web sitelerinde bulunan ya da bunlar üzerinden
        erişilebilen herhangi bir İçerik, materyal yahut diğer bilgiler veya
        diğer web sitelerini kullanmanız sonucunda elde edebileceğiniz sonuçlar
        tarafımızca veya ilgili bağlı ortaklıklarımızdan herhangi tarafından
        desteklenmemekte olup bunlarla herhangi bir beyan veya taahhüdümüz
        bulunmamakta ya da tarafımızca garanti verilmemektedir. Hizmetler’le
        bağlantılı olan veya Hizmetler üzerinden başka herhangi bir web sitesine
        erişmeye karar vermeniz durumunda bu işlem, riski tamamen size ait olmak
        tarafınızca gerçekleştirilecektir
      </p>
      <h4>3.1.12. Beyan ve Taahhütler</h4>
      <p>
        18 yaşından büyük olduğunuzu, işbu Sözleşme’yi akdetme hakkına ve
        yetkisine sahip olduğunuzu, işbu Sözleşme’deki hüküm, şart ve
        yükümlülükleri yerine getirmeye tam olarak yetkin ve ehil olduğunuzu,
        Hizmetler’i kullanımınızın tüm Yürürlükteki Kanunlar, emirler,
        yönetmelikler, nizamnameler vb. ile uyumlu olduğunu ve olacağını beyan
        etmektesiniz. İşbu Sözleşme şartlarını okuduğunuzu, anladığınızı, kabul
        ettiğinizi ve bunlara uyacağınızı beyan etmektesiniz. Buna ek olarak,
        gönderilerinizin ve bunların tüm unsurlarının (a) münhasıran ve sadece
        Size ait olduğunu veya tarafınızca kontrol edildiğini, gönderilerinizde
        yer alan içeriğin hak sahibinden önceden yazılı izin aldığınızı yahut
        burada Aloha’ya sağlanan tüm hakların sağlanması hususunda diğer bir
        şekilde yasal olarak yetkili olduğunuzu; (b) Kullanıcı ve Dinleyici
        Gönderileri’nizin burada açıklandığı veya tasarlandığı şekilde Aloha
        tarafından kullanılmasının, herhangi bir kişi veya kuruluşun telif
        haklarını, ticari marka haklarını, tanıtım haklarını veya diğer
        haklarını ihlal etmediğini ve etmeyeceğini, herhangi bir yasayı,
        düzenlemeyi veya herhangi bir hakkı ihlal etmediğini ya da herhangi bir
        sınırlama olmaksızın, isminin kullanılması, gizlilik hakları ve
        aşağılama dahil ve ancak bunlarla sınırlı olmaksızın, herhangi bir dava
        edilebilir iddia veya yükümlülüğe yol açmadığını beyan ve taahhüt
        etmektesiniz. Buna ek olarak, Size ait Kullanıcı Gönderileri’nin ve
        bunları paylaşmanın veya yayınlamanın sonuçlarından dolayı sorumluluk,
        yalnızca tarafınıza ait olacaktır.
      </p>
      <h4>3.1.13. Kablosuz Özellikler</h4>
      <p>
        Hizmetler, kablosuz cihazınız aracılığıyla tarafınızca kullanılabilecek
        belirli özellikler ve Hizmetler sunabilir. Kablosuz Özellikler’ini
        kullanmak için standart mesajlaşma, veri ve diğer ücretler, operatörünüz
        tarafından alınabilecektir. Ücretler ve masrafların kablosuz faturanıza
        yansıtılması mümkündür. Operatörünüzün belirli Kablosuz Özellikler’i
        yasaklaması veya kısıtlaması mümkün olup bazı Kablosuz Özellikler’in,
        operatörünüz veya kablosuz cihazınızla uyumlu olmaması ihtimali
        mevcuttur. Mevcut planların ve maliyetlerinin öğrenilmesi için
        operatörünüze danışmanız gerekmektedir. Lütfen bu konulardaki
        sorularınız için operatörünüze başvurun. Kablosuz cihazınız aracılığıyla
        tarafınızdan topladığımız verilerle ilgili bilgiler, bu Sözleşme’nin bir
        parçasını oluşturan Kişisel Verilerin Korunması Politikamız’da yer
        almaktadır. Herhangi bir güvenlik ihlali veya yetkisiz kullanım
        durumunda derhal Aloha’yı bilgilendirmeniz gerekmektedir. Her ne kadar
        mobil cihazınızın herhangi bir biçimde yetkisiz kullanımından
        kaynaklanan kayıplarınızdan ötürü Aloha’nın sorumluluğu bulunmamakta ise
        de, bu tür yetkisiz kullanım nedeniyle Aloha’nın veya diğer kişilerin
        zararlarından ötürü Sizin sorumluluğunuz gündeme gelebilecektir.
      </p>
      <h3>3.2. Hizmetler’e İlişkin Ücret Hükümleri</h3>
      <p>
        Hizmet’e ilişkin yansıtılan ALC Coin ve kredi bedelleri, akıllı mobil
        cihazlarınızda bulunan uygulama marketinden satın alınmakta olup, Aloha
        kredi kartı bilgilerinizi işlememektedir. Ücretler nihai olup iadesi
        mümkün değildir. <br />
        <br />
        Güncel kredi bedellerine, buradan; güncel dinleyici ALC Coin bedellerine{" "}
        <a
          className="text-dark fw-bold"
          target="_blank"
          rel="noreferrer"
          href="Https://indir.alohalive.online"
        >
          indir.alohalive.online
        </a>{" "}
        üzerinden ulaşabilirsiniz. <br />
        <br />
        Bununla birlikte, Hizmet’i kullandığı esnada hesaplanan ve tahsil
        edilen/yansıtılan hizmet bedelinden dolayı şikâyette bulunmak isteyen
        Kullanıcı’nın, Hizmet’in gerçekleştiği ayın sonundan en az 7 gün önce
        Aloha’ya, yaklaşık kullanım süresi ile ilgili tüm bilgi ve gerekçeleri
        içeren yazılı bir dilekçe ile birlikte bildirimde bulunması
        gerekmektedir. Uygulama arayüzünden, Konuşmacılar Bakiye Coinlerini,
        Dinleyiciler Kazanç Coinlerine her zaman erişebilir
      </p>
      <h4>3.2.1. Promosyon Kodları</h4>
      <p>
        Promosyon kodları (indirimler), kuponlar tek seferlik teklifler olup
        yalnızca Uygulama üzerinden kullanılabilmektedir. İndirimler anlaşmalı e
        -ticaret sitesi ve hesap başına bir adet ile sınırlıdır ve diğer
        tekliflerle birleştirilmesi mümkün değildir. İndirimler transfer
        edilemez ve yeniden satılamaz.
      </p>
      <h2>4. FESİH</h2>
      <h3>4.1. Karşılıklı Fesih</h3>
      <p>
        Aloha ve Kullanıcı, yazılı bildirimde bulunmak kaydıyla (burada “yazılı”
        terimi, e-postayı içermektedir) işbu Sözleşme’yi derhal ve mahkeme
        kararına gerek olmaksızın feshetme hakkına sahip olacaktır.
      </p>
      <h3>4.2. Aloha Tarafından Fesih</h3>
      <p>
        Aloha, bildirimde bulunmaksızın ve mahkeme kararına gerek olmaksızın
        fesih ve/veya Hizmetler’e erişiminizi herhangi bir nedenle askıya alma
        hakkına sahiptir. Özellikle aşağıdaki durumlarda Aloha’nın fesih hakkı
        bulunmaktadır: (a) Aloha’ya bu Sözleşme kapsamında ödenmesi gereken
        herhangi bir tutarın tarafınızca ödenmemesi veya (b) işbu Sözleşme’nin
        herhangi bir hüküm veya şartının tarafınızca esaslı olarak ihlal
        edilmesi durumunda. İşbu Sözleşme’nin herhangi bir nedenle feshedilmesi
        üzerine Hizmetler’e erişiminiz ve Hizmetler’i kullanımınız
        sonlandırılacaktır.
      </p>
      <h3>4.3. Feshin Sonuçları</h3>
      <p>
        İşbu Sözleşme’nin feshi, fesihten önce gerçekleşen herhangi bir Hizmet
        için ödeme yükümlülüğünüze veya fesih tarihinden önce taraflardan
        herhangi birinin Sözleşme kapsamında üstlenebileceği diğer
        yükümlülüklere, haklara veya borçlara halel getirmemektedir
      </p>
      <h3>4.4. Tazminat</h3>
      <p>
        Hizmetler ile ilgili ihlaller dahil olmak üzere, kullanıcıların ve
        dinleyicilerin Sözleşme’deki yükümlülüklerine uymaması durumunda
        Aloha’nın katlanacağı tüm masraflar, uğrayacağı tüm zararlar ve yapacağı
        harcamalar (avukat ücretleri dahil) bakımından Kullanıcı'nın, Aloha’yı
        tamamen tazmin etmesi ve tüm zararlardan beri kılması gerekmektedir.
      </p>
      <h2>5. GİZLİLİK</h2>
      <p>
        Tüm isimler,e-posta adresleri, şifreler, kredi satın alma ve harcama
        bilgileri ve diğer bilgiler dahil olmak üzere, Aloha tarafından tutulan
        ve Kullanıcılar’a ait tüm kişisel bilgilerin, web sitemizde ve uygulama
        üzerinde erişime açık olan Kişisel Verileri Koruma Politikasına uygun
        olarak Aloha tarafından toplanacağını, saklanacağını ve kullanılacağını
        anlamakta ve kabul etmektesiniz.
      </p>
      <h2>6. ABONELİKLER</h2>
      <p>
        Kullanıcı’nın ayrıca kabul etmesi gereken belirli hüküm ve şartlara tabi
        olarak Aloha tarafından Hizmetler’in kullanımı hususunda aylık
        abonelikler sunma imkânı bulunmaktadır.
      </p>
      <h2>7. BİLDİRİMLER</h2>
      <p>
        <a
          className="text-dark fw-bold"
          target="_blank"
          href="mailto:aloha@alohalive.online"
          rel="noreferrer"
        >
          aloha@alohalive.online
        </a>{" "}
        adresine gönderilecek e-postalar üzerinden Aloha ile iletişim kurulması
        mümkündür.
      </p>
      <h2>8. MÜCBİR SEBEP</h2>
      <p>
        Yangın, yıldırım, patlama, güç dalgalanması veya arızası, su, savaş,
        devrim, sivil kargaşa veya sivil ya da askeri yetkililerin veya ortak
        düşmanlarının eylemleri, işbu Sözleşme tarihinden sonra yürürlüğe giren
        ve Sözleşme hükümlerinin tamamen veya kısmen yerine getirilmesini
        engelleyen herhangi bir hükümet organının veya yasal organın ya da bu
        tür herhangi bir hükümet organı veya yasal organın herhangi bir
        temsilcisinin herhangi bir kanunu, emri, yönetmeliği, tüzüğü ya da şartı
        yahut grevler, yavaşlatmalar, grev gözcülüğü veya boykotlar dahil ancak
        bunlarla sınırlı olmamak üzere işçi kargaşaları; hammadde teminini,
        ulaşım tesislerini, yakıt veya enerji kıtlıklarını, siber saldırılar
        veya ortak taşıyıcıların eylemleri yahut ihmallerini güvence altına
        alamama dahil, ancak bunlarla sınırlı olmaksızın, tarafların makul
        kontrolleri dışındaki eylemler veya olaylardan dolayı meydana gelen
        hasarlardan ya da ifadaki gecikmelerden yahut Sözleşme’nin ifa
        edilmemesinden ötürü Aloha’nın veya sizin sorumluluğunuz
        bulunmayacaktır.
      </p>
      <h2>9. UYGULANACAK HUKUK</h2>
      <p>
        İşbu Sözleşme’den doğan her türlü anlaşmazlık, yürürlükteki Türk
        kanunları uyarınca çözümlenecektir. İşbu Sözleşme’nin uygulanması
        hususunda Kullanıcı’ya verilecek hizmet için, Hizmeti kullandığı ve
        ikametgahının bulunduğu yerdeki Tüketici Hakem Heyetleri ve Tüketici
        Mahkemeleri yetkilidir. 6502 sayılı Tüketicinin Korunması Hakkında
        Kanunda belirtilen alt ve üst limitler kapsamında, tüketici talepleri
        için ilçe/il tüketici hakem heyetleri yetkilidir.
      </p>
      <h2>10. FERAGAT VE AYRILABİLİRLİK</h2>
      <p>
        İşbu Sözleşme’nin herhangi bir hükmünün ihlalinden feragat edilmesi,
        Sözleşme’nin herhangi bir başka ihlalinden veya diğer bir hükmünden
        feragat edildiği anlamına gelmemektedir. İşbu Sözleşme’nin hükümleri
        birbirinden bağımsız ve ayrılabilir nitelikte olup bir hükümden herhangi
        birinin veya diğerlerinin kısmen yahut tamamen geçersiz ya da
        uygulanamaz olması nedeniyle hiçbir hüküm etkilenmeyecek veya geçersiz
        yahut uygulanamaz hale getirilmeyecektir.
      </p>
      <h2>11. KÜMÜLATİF ÇÖZÜM</h2>
      <p>
        Bu Sözleşme kapsamında verilen veya bu Sözleşme’de atıfta bulunulan tüm
        haklar ve çözümlerden hiçbiri münhasır olmayıp bunların tamamı
        kümülatiftir ve bunlardan birine başvurulması, bir başkasının veya
        kanunla sağlanan başka bir hak veya çözüm yolunun mevcudiyetini yahut
        uygulanabilirliğini engellememektedir.
      </p>
      <h2>12. NİHAİ ANLAŞMA; ALOHA TARAFINDAN DEĞİŞİKLİK</h2>
      <p>
        İşbu Sözleşme, değindiği konuyla ilgili olarak Taraflar arasındaki tam,
        nihai ve münhasır olarak entegre edilmiş mutabakatı içermektedir.
        Herhangi bir zamanda ve zaman zaman, tamamen kendi takdirine bağlı
        olarak Aloha’nın işbu Sözleşme’yi tadil etme, değiştirme veya düzenleme
        hakkı bulunmaktadır. Aloha’nın işbu Sözleşme’de değişiklik yapmayı
        önermesi durumunda Aloha öncelikle Konuşmacı’yı ve Dinleyici’yi
        bilgilendirecek ve onlara bir kutuyu işaretleyerek yeni sözleşmeyi kabul
        etme fırsatı sağlanacaktır. Ek olarak Aloha, Uygulama’da bir bildirim
        yayınlayacaktır. Yeni sözleşmenin şartlarının Kullanıcı tarafından kabul
        edilmemesi durumunda mevcut sözleşme feshedilecek ve Kullanıcı’nın
        Hizmetler’i kullanabilmesi mümkün olmayacaktır.
      </p>
      <h2>SÖZLEŞMENİN YORUMLANMASI</h2>
      <p>
        İşbu Sözleşme’deki başlıklar, Sözleşme’nin yorumlanmasını
        etkilemeyecektir. “Dahil”, “dahil ancak bununla sınırlı olmamak
        kaydıyla” anlamına gelmektedir. İçerik aksini gerektirmedikçe, tekil
        veya çoğul sayıdaki kelimelerin her biri tekil veya çoğul sayıyı
        içerecektir. Tüm zamirler eril, dişil ve nötr zamir formlarını
        içermektedir.
      </p>
      <h2>13. İŞBU SÖZLEŞMENİN GÖNÜLLÜ OLARAK AKDEDİLMESİ</h2>
      <p>
        şbu Sözleşme, kısmen veya Aloha adına herhangi bir zorlama veya usulsüz
        etki olmaksızın gönüllü olarak, karşılık ile imzalanmıştır. Kullanıcı
        (a) bu Sözleşme’yi okuduğunu; (b) özellikle Kullanıcı’nın sorumlulukları
        ve olası yükümlülükleri başta olmak üzere, Sözleşme’nin şartlarını ve
        sonuçlarını anladığını ve (c) bu Sözleşme’nin yasal ve bağlayıcı
        etkisinin tamamen farkında olduğunu beyan etmektedir.
      </p>
      <h2>14. FERAGAT; SORUMLULUK REDDİ; RİSKİN ÜSTLENİLMESİ</h2>
      <p>
        Yürürlükteki Kanunlar’ın izin verdiği en geniş ölçüde, Kullanıcı
        tarafından Aloha Hizmetler’inin, kullanımıyla ilgili olarak Aloha ve tüm
        bağlı ortaklıkları; ticari elverişlilik ve belirli bir amaca uygunluk
        garantileri dahil olmak üzere, tüm açık ve zımni garantileri
        reddetmektedir. Tüm Aloha Hizmetler’i, “olduğu gibi” ve “mümkün olduğu
        gibi” sağlanmakta olup Kullanıcı ve Dinleyici, riskleri kendisine ait
        olmak üzere bunlara dayanmaktadır. İşbu Sözleşme kapsamında Aloha’nın
        yükümlülüğü, yasal olarak sınırlandırılamayan yükümlülükler hariç olmak,
        Sözleşme kapsamında tarafınızca Aloha’ya uygulama marketi aracılığıyla
        ödenen ücretlerin toplam tutarı ve dinleyici hizmet bedeli de burada
        gösterilen kazanç usulüyle ile sınırlandırılmıştır. Aloha, herhangi bir
        dolaylı veya netice kabilinden doğan zarardan dolayı sorumlu
        olmayacaktır. <br />
        <br />
        Aloha, teknik aksaklıklar sebebiyle hizmetten faydalanılamaması
        durumunda yükümlülüklerini ifa edememesi nedeniyle sorumlu tutulamayacak
        ve kendisinden herhangi bir nam altında herhangi bir talepte
        bulunulamayacaktır.
      </p>
      <h2>15. DELİL SÖZLEŞMESİ</h2>
      <p>
        Kullanıcı, işbu Sözleşme’den doğabilecek ihtilaflarda, Aloha’ya ait
        resmi defter ve ticari kayıtların, veri tabanında ve sunucularında
        tutulan elektronik bilgilerin ve bilgisayar kayıtlarının; 6100 sayılı
        Hukuk Muhakemeleri Kanunu’nun 193. maddesi anlamında bağlayıcı, nihai ve
        münhasır delil olarak kabul edileceğini beyan ve taahhüt etmektedir.
      </p>
      <h2>16. SÖZLEŞMENİN BÜTÜNÜ</h2>
      <p>
        İşbu Sözleşme, Sözleşme’nin konusuyla ilgili olarak Taraflar arasındaki
        Sözleşme’nin bütününü içermekte olup bu konularla ilgili olarak Taraflar
        arasında daha önce yapılmış herhangi bir yazılı veya sözlü anlaşmanın
        yerine geçmektedir. Elektronik imzalar, ıslak imzalar gibi geçerli ve
        bağlayıcı olarak kabul edilecektir.
      </p>
      <h2>17. KULLANICI TARAFINDAN SÖZLEŞMENİN KABUL EDİLMESİ</h2>
      <p>
        Özellikle Feragat; Sorumluluk Reddi ve Riskin Üstlenilmesi başlıklı 15.
        madde dahil olmak üzere, hüküm ve şartları okuduğumu ve açıkça kabul
        ettiğimi beyan eder; bu bölümün yasal haklarımı ve hukuki çarelerimi
        sınırlandırdığını kabul ederim. Bu Sözleşme’de açıkladığım rızam ile,
        yasaların izin verdiği en geniş ölçüde, tüm sorumluluğun eksiksiz ve
        koşulsuz bir şekilde sınırlandırılmasını sağlamak niyetindeyim. En az 18
        yaşında olduğumu, Hizmet’i münhasıran kendi tüketimim için kullanacağımı
        kabul, beyan ve taahhüt ederim.
      </p>
    </div>
  );
};

export default UserAgreement;
