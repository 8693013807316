import React from "react";

const ApplicationForm = () => {
  return (
    <div className="privacy-container">
      <h2 className="text-center">
        ALOHA DİJİTAL BİLİŞİM EĞİTİM VE DANIŞMANLIK A.Ş. VERİ SAHİBİ BAŞVURU
        FORMU
      </h2>
      <h3 className="fw-bold">GENEL AÇIKLAMALAR</h3>
      <p>
        6698 Sayılı Kişisel Verilerin Korunması Kanunu’nda (“KVK Kanunu”) ilgili
        kişi olarak tanımlanan kişisel veri sahiplerine (Bundan sonra “Başvuru
        Sahibi” olarak anılacaktır), KVK Kanunu’nun 11’inci maddesinde kişisel
        verilerinin işlenmesine ilişkin birtakım taleplerde bulunma hakkı
        tanınmıştır. <br />
        KVK Kanunu’nun 13’üncü maddesinin birinci fıkrası uyarınca; veri
        sorumlusu olan Şirketimize bu haklara ilişkin olarak yapılacak
        başvuruların yazılı olarak veya Kişisel Verilerin Korunması Kurulu
        (“Kurul”) tarafından belirlenen diğer yöntemlerle tarafımıza iletilmesi
        gerekmektedir. <br />
        <br />
        Bu çerçevede “yazılı” olarak Şirketimize yapılacak başvurular, işbu
        formun çıktısı alınarak;
      </p>
      <ul>
        <li>Başvuru Sahibi’nin şahsen başvurusu ile,</li>
        <li>Noter vasıtasıyla,</li>
        <li>
          Başvuru Sahibi’nce 5070 Sayılı Elektronik İmza Kanunu’nda tanımlı olan
          “güvenli elektronik imza” ile imzalanarak Şirket kayıtlı elektronik
          posta adresine gönderilmek suretiyle,
        </li>
      </ul>
      <p>tarafımıza iletilebilecektir.</p> <br />
      <p>
        Aşağıda, yazılı başvuruların ne şekilde tarafımıza ulaştırılacağına
        ilişkin yazılı başvuru kanalları özelinde bilgiler verilmektedir.
      </p>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">Başvuru Yöntemi </th>
            <th scope="col">Başvurunun Yapılacağı Adres</th>
            <th scope="col">Başvuru Gönderiminde Belirtilecek Bilgi</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row" className="fw-normal">
              Şahsen Başvuru (Başvuru sahibinin bizzat gelerek kimliğini tevsik
              edici belge ile başvurması)
            </th>
            <td>
              Barbaros Mahallesi Begonya Sokak Nidakule Ataşehir Batı Blok No:1
              İç Kapı No:2 Ataşehir İstanbul
            </td>
            <td>
              Zarfın üzerine
              <em>
                {" "}
                “Kişisel Verilerin Korunması Kanunu Kapsamında Bilgi Talebi”{" "}
              </em>
              yazılacaktır
            </td>
          </tr>
          <tr>
            <th scope="row" className="fw-normal">
              Noter vasıtasıyla tebligat
            </th>
            <td>
              Barbaros Mahallesi Begonya Sokak Nidakule Ataşehir Batı Blok No:1
              İç Kapı No:2 Ataşehir İstanbul
            </td>
            <td>
              Tebligat zarfına{" "}
              <em>
                “Kişisel Verilerin Korunması Kanunu Kapsamında Bilgi Talebi”
              </em>{" "}
              yazılacaktır.
            </td>
          </tr>
          <tr>
            <th className="fw-normal" scope="row">
              Komite Mail Adresine İletilmesi{" "}
            </th>
            <td>
              <a
                className="text-dark fw-bold"
                target="_blank"
                rel="noreferrer"
                href="mailto:kvkk@alohalive.online
"
              >
                kvkk@alohalive.online
              </a>
            </td>
            <td>
              E-posta’nın konu kısmına{" "}
              <em>“Kişisel Verilerin Korunması Kanunu Bilgi Talebi”</em>{" "}
              yazılacaktır.
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        Ayrıca, Kurul’un belirleyeceği diğer yöntemler duyurulduktan sonra bu
        yöntemler üzerinden de başvuruların ne şekilde alınacağı Şirketimizce
        duyurulacaktır.
      </p>{" "}
      <br />
      <p>
        Tarafımıza iletilmiş olan başvurularınız KVK Kanunu’nun 13’üncü
        maddesinin 2’inci fıkrası gereğince, talebin niteliğine göre talebinizin
        <strong> bizlere ulaştığı tarihten</strong> itibaren otuz gün içinde
        yanıtlandırılacaktır. Yanıtlarımız ilgili KVK Kanunu’nun 13’üncü maddesi
        hükmü gereğince yazılı veya elektronik ortamdan tarafınıza
        ulaştırılacaktır.
      </p>
      <p>
        {" "}
        <strong>A. Başvuru Sahibi iletişim bilgileri:</strong>
      </p>
      <ul>
        <li>İsim:</li>
        <li>Soy isim:</li>
        <li>TC Kimlik Numarası:</li>
        <li>Telefon Numarası:</li>
        <li>
          {" "}
          E-posta: (Belirtmeniz halinde size daha hızlı yanıt verebileceğiz.)
        </li>
        <li>Adres:</li>
      </ul>
      <p>
        {" "}
        <strong>
          B. Lütfen Şirketimiz ile olan ilişkinizi belirtiniz.
        </strong>{" "}
        <em>
          {" "}
          (Kullanıcı(müşteri), iş ortağı, çalışan adayı, eski çalışan, üçüncü
          taraf firma çalışanı, hissedar, tedarikçi gibi)
        </em>
      </p>
      <ul>
        <li style={{ listStyle: "none" }}>
          <input
            className="form-check-input"
            type="checkbox"
            defaultValue=""
            id="defaultCheck1"
          />
          <label className="form-check-label ms-2" htmlFor="defaultCheck1">
            Müşteri (Uygulama Kullanıcısı)
          </label>
        </li>
        <li style={{ listStyle: "none" }}>
          <input
            className="form-check-input"
            type="checkbox"
            defaultValue=""
            id="defaultCheck1"
          />
          <label className="form-check-label ms-2" htmlFor="defaultCheck1">
            Ziyaretçi
          </label>
        </li>
        <li style={{ listStyle: "none" }}>
          <input
            className="form-check-input"
            type="checkbox"
            defaultValue=""
            id="defaultCheck1"
          />
          <label className="form-check-label ms-2" htmlFor="defaultCheck1">
            İş ortağı
          </label>
        </li>
        <li style={{ listStyle: "none" }}>
          <input
            className="form-check-input"
            type="checkbox"
            defaultValue=""
            id="defaultCheck1"
          />
          <label className="form-check-label ms-2" htmlFor="defaultCheck1">
            Diğer: .................
          </label>
        </li>
        <hr />
        <li style={{ listStyle: "none" }}>
          Şirketimiz içerisinde iletişimde olduğunuz Birim:
          .........................
        </li>
        <li style={{ listStyle: "none" }}>Konu: .........................</li>
        <hr />
        <li style={{ listStyle: "none" }}>
          <input
            className="form-check-input"
            type="checkbox"
            defaultValue=""
            id="defaultCheck1"
          />
          <label className="form-check-label ms-2" htmlFor="defaultCheck1">
            Eski Çalışanım, Çalıştığım Yıllar : ............................
          </label>
        </li>
        <li style={{ listStyle: "none" }}>
          <input
            className="form-check-input"
            type="checkbox"
            defaultValue=""
            id="defaultCheck1"
          />
          <label className="form-check-label ms-2" htmlFor="defaultCheck1">
            İş Başvurusu / Özgeçmiş Paylaşımı Yaptım Tarih :
            ............................
          </label>
        </li>
        <li style={{ listStyle: "none" }}>
          <input
            className="form-check-input"
            type="checkbox"
            defaultValue=""
            id="defaultCheck1"
          />
          <label className="form-check-label ms-2" htmlFor="defaultCheck1">
            Üçüncü Kişi Firma Çalışanıyım Lütfen çalıştığınız firma ve pozisyon
            bilgisini belirtiniz : ............................
          </label>
        </li>
        <li style={{ listStyle: "none" }}>
          <input
            className="form-check-input"
            type="checkbox"
            defaultValue=""
            id="defaultCheck1"
          />
          <label className="form-check-label ms-2" htmlFor="defaultCheck1">
            Diğer: ............................
          </label>
        </li>
      </ul>
      <p>
        {" "}
        <strong>
          C. Lütfen KVK Kanunu kapsamındaki talebinizi detaylı olarak
          belirtiniz:
        </strong>
      </p>
      <p>
        …………………..…………….……………………………….……………………………….…
        ……………………………………………………………………………………………………
        ……………………………………………………………………………………………………
        ……………………………………………………………………………………………………
        ……………………………………………………………………………………………………
        ……………………………………………………………………………………………………
        ……………………………………………………………………………………………………
        ……………………………………………………………………………………………………
        ……………………………………………………………………………………………………
        ……………………………………………………………………………………………………
        ……………………………………………………………………………………………………
        ……………………………………………………………………………………………………
        ……………………………………………………………………………………………………
        ……………………………………………………………………………………………………
        ……………………………………………………………………………………………………
        ……………………………………………..…………………..…………….……………………
        ………….……………………………….…………………………………………………………
        ……………………………………………………………………………………………………
        ……………………………………………………………………………………………………
      </p>
      <p>
        {" "}
        <strong>
          D.Lütfen başvurunuza vereceğimiz yanıtın tarafınıza bildirilme
          yöntemini seçiniz:
        </strong>
      </p>
      <ul>
        <li>Adresime gönderilmesini istiyorum.</li>
        <li>
          E-posta adresime gönderilmesini istiyorum.
          <em>
            (E-posta yöntemini seçmeniz hâlinde size daha hızlı yanıt
            verebileceğiz.)
          </em>
          ,
        </li>
        <li>Elden teslim almak istiyorum.</li>
        <li style={{ listStyle: "none" }}>
          <em>
            (Vekâleten teslim alınması durumunda noter tasdikli vekâletname veya
            yetki belgesi olması gerekmektedir.)
          </em>
        </li>
      </ul>
      <p>
        İşbu başvuru formu, Şirketimiz ile olan ilişkinizi tespit ederek varsa,
        Şirketimiz tarafından işlenen kişisel verilerinizi eksiksiz olarak
        belirleyerek, ilgili başvurunuza doğru ve kanuni süresinde cevap
        verilebilmesi için tanzim edilmiştir. Hukuka aykırı ve haksız bir
        şekilde veri paylaşımından kaynaklanabilecek hukuki risklerin bertaraf
        edilmesi ve özellikle kişisel verilerinizin güvenliğinin sağlanması
        amacıyla, kimlik ve yetki tespiti için Şirketimiz ek evrak ve malumat
        (Nüfus cüzdanı veya sürücü belgesi sureti vb.) talep etme hakkını saklı
        tutar. Form kapsamında iletmekte olduğunuz taleplerinize ilişkin
        bilgilerin doğru ve güncel olmaması ya da yetkisiz bir başvuru yapılması
        halinde Şirketimiz, söz konusu yanlış bilgi ya da yetkisiz başvuru
        kaynaklı taleplerden dolayı mesuliyet kabul etmemektedir. Şirketimiz,
        başvurunun araştırılması veya cevaplanması için olağandan fazla çaba
        gerekmesi durumunda başvuru karşılığında makul bir ücret talep etme
        hakkınız saklı tutmaktadır.
      </p>
      <ul>
        <p>Başvuru Sahibi</p>
        <li>(Kişisel Veri Sahibi) Adı:</li>
        <li>Soyadı:</li>
        <li>Başvuru Tarihi:</li>
        <li>İmza:</li>
      </ul>
    </div>
  );
};

export default ApplicationForm;
