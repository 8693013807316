import React from 'react';

const DataRetentionTable = () => {
  return (
    <div>
      <h2>8. Veri Saklama ve İmha Süreçleri</h2>
      <p>
        Kişisel verilerin saklanması ve imhası süreleri ve saklama ve imha usullerine ilişkin
        politika oluşturulmuştur.
      </p>
      <p>
        Veri sorumlusu, her yıl Ocak ayında verileri, süreleri ve amaçları gözden geçirecek ve
        imha süreçlerini başlatacak; imha kayıtlarını üç yıl süreyle saklayacaktır.
      </p>
      <p>
        Bu Politika'nın Şirket web sitesinde yayınlanmasına, tüm veri sahiplerine, veri
        alıcılarına sair yollarla ulaştırılmasına ve güncelliğinden Şirket Yönetim Kurumu
        sorumludur.
      </p>
      <h3>Revizyon Geçmişi Kayıtları</h3>
      <table style={{ border: '1px solid black', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={tableHeaderStyle}>Sayı</th>
            <th style={tableHeaderStyle}>Revizyon Tanımı</th>
            <th style={tableHeaderStyle}>Onay</th>
            <th style={tableHeaderStyle}>Yayım Tarihi</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={tableCellStyle}>1</td>
            <td style={tableCellStyle}>İlk Yayım</td>
            <td style={tableCellStyle}></td>
            <td style={tableCellStyle}>Haziran 2022</td>
          </tr>
          <tr>
            <td style={tableCellStyle}>2</td>
            <td style={tableCellStyle}>Güncelleme</td>
            <td style={tableCellStyle}></td>
            <td style={tableCellStyle}></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const tableHeaderStyle = {
  border: '1px solid black',
  padding: '8px',
  textAlign: 'left',
  backgroundColor: '#f2f2f2'
};

const tableCellStyle = {
  border: '1px solid black',
  padding: '8px',
  textAlign: 'left'
};

export default DataRetentionTable;